
import './DeviceResolution.scss';
import { useEffect, useState, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDevice } from '../../store/actions/deviceTypeActions';
import { devicesList } from '../../data/devicesList';


function DeviceResolution() {
  let mobileSmall = 320;
  let mobile = 360;
  let tablet = 768;
  let desktop = 1280;

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  const deviceTypeDispatch = useDispatch();
  const deviceType = useSelector((state) => state.deviceType.device);

  useEffect(() => {

    handleResize();
    // handler to call on window resize
    function handleResize() {
      //set window dimensions to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })

      if (windowSize.width > 0 && windowSize.width <= mobile) {
        deviceTypeDispatch(setDevice(devicesList.mobile_w_max_360));
      }else if (windowSize.width > mobile && windowSize.width <= tablet) {
        deviceTypeDispatch(setDevice(devicesList.mobile));
      } else if (windowSize.width > tablet && windowSize.width <= desktop) {
        deviceTypeDispatch(setDevice(devicesList.tablet));
      } else if (windowSize.width > desktop) {
        deviceTypeDispatch(setDevice(devicesList.desktop));
      }
    }
    //add event listener
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);

  }, [windowSize.width, deviceType])

  return (
    <></>
  );
}

export {DeviceResolution};
