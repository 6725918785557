const Icon1 = ({_width, _height, _color}) => {
    
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width={_width} height={_height}viewBox="0 0 240.000000 240.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)"
fill={_color} stroke="none">
<path d="M435 1898 c-51 -18 -96 -59 -116 -104 -19 -41 -19 -68 -17 -472 l3
-428 28 -42 c15 -23 46 -52 70 -64 40 -22 54 -23 305 -26 l263 -3 -3 -32 c-3
-29 -7 -32 -38 -35 -54 -4 -90 -22 -105 -53 -21 -40 -19 -63 11 -98 l26 -31
328 0 c316 0 328 1 354 21 21 16 26 29 26 62 -1 66 -36 97 -109 97 -40 0 -41
1 -41 34 l0 35 263 3 c224 3 267 5 293 20 45 25 92 82 104 128 7 27 10 177 8
448 l-3 407 -27 47 c-21 34 -41 53 -80 72 l-52 26 -730 -1 c-482 0 -741 -4
-761 -11z m1533 -58 c18 -11 41 -34 52 -52 19 -32 20 -52 20 -453 0 -401 -1
-421 -20 -453 -11 -18 -34 -41 -52 -52 -31 -19 -51 -20 -317 -20 l-284 0 7
-61 c3 -34 6 -73 6 -85 0 -23 4 -24 63 -24 36 0 68 -5 75 -12 17 -17 15 -44
-4 -62 -13 -14 -56 -16 -315 -16 -188 0 -308 4 -320 10 -12 7 -19 21 -19 40 0
33 11 38 95 42 l50 3 6 75 c3 41 4 78 2 83 -2 4 -129 7 -282 7 -272 0 -279 0
-311 23 -18 12 -43 35 -54 50 -21 28 -21 38 -21 445 0 463 0 464 64 508 l36
24 745 0 c726 0 746 -1 778 -20z"/>
<path d="M478 1739 c-17 -9 -18 -39 -18 -394 0 -211 3 -390 6 -399 6 -14 79
-16 725 -16 676 0 720 1 727 17 4 10 7 190 7 401 0 329 -2 384 -15 392 -21 14
-1409 13 -1432 -1z m376 -65 c22 -9 10 -24 -20 -24 -29 0 -29 -1 -4 -42 25
-41 25 -48 0 -48 -19 0 -20 -7 -20 -130 0 -123 1 -130 20 -130 25 0 25 -7 0
-48 -21 -34 -25 -53 -12 -51 4 1 18 1 32 0 17 -1 21 -4 13 -12 -13 -13 -130
-8 -123 6 3 6 15 10 28 9 27 -1 28 6 2 48 -25 41 -25 48 0 48 19 0 20 7 20
130 0 123 -1 130 -20 130 -25 0 -25 7 0 48 25 41 25 42 -4 42 -26 0 -40 12
-29 24 8 8 96 8 117 0z m356 -104 l0 -90 135 0 135 0 0 -140 0 -140 -287 2
-288 3 -3 215 c-1 118 0 220 2 227 4 10 43 13 156 13 l150 0 0 -90z m440 -94
c0 -9 -8 -12 -25 -8 -31 5 -32 -11 -5 -56 l21 -34 -21 -5 c-29 -8 -29 -68 1
-76 l21 -6 -21 -34 c-27 -45 -27 -57 4 -57 14 0 25 -4 25 -10 0 -6 -28 -10
-65 -10 -37 0 -65 4 -65 10 0 6 14 10 30 10 35 0 36 5 10 45 -25 38 -25 55 0
55 16 0 20 7 20 35 0 28 -4 35 -20 35 -25 0 -25 17 0 55 26 40 25 46 -6 43
-15 -2 -29 3 -31 10 -4 9 13 12 61 12 49 0 66 -4 66 -14z m-740 -357 l0 -31
34 21 c40 25 56 27 56 6 0 -13 28 -15 195 -15 190 0 195 1 195 20 0 20 2 20
45 -6 l45 -26 0 31 c0 17 5 31 10 31 6 0 10 -28 10 -65 0 -37 -4 -65 -10 -65
-5 0 -10 14 -10 31 l0 31 -45 -26 c-43 -26 -45 -26 -45 -6 0 19 -5 20 -195 20
-191 0 -195 0 -195 -21 0 -20 -1 -20 -45 4 l-45 26 0 -30 c0 -19 -5 -29 -15
-29 -12 0 -15 14 -15 65 0 51 3 65 15 65 10 0 15 -10 15 -31z"/>
<path d="M940 1590 c0 -25 4 -30 25 -30 21 0 25 5 25 30 0 25 -4 30 -25 30
-21 0 -25 -5 -25 -30z"/>
<path d="M1030 1590 c0 -25 4 -30 25 -30 21 0 25 5 25 30 0 25 -4 30 -25 30
-21 0 -25 -5 -25 -30z"/>
<path d="M1134 1612 c-15 -9 -14 -40 1 -46 19 -7 37 10 33 29 -4 21 -18 28
-34 17z"/>
<path d="M940 1515 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1030 1515 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1120 1515 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1043 1463 c-7 -3 -13 -16 -13 -29 0 -19 5 -24 25 -24 21 0 25 5 25
28 0 28 -10 34 -37 25z"/>
<path d="M940 1435 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1120 1435 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1210 1425 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1300 1425 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1400 1425 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M940 1360 c0 -25 4 -30 25 -30 21 0 25 5 25 30 0 25 -4 30 -25 30
-21 0 -25 -5 -25 -30z"/>
<path d="M1032 1363 c2 -20 9 -28 23 -28 14 0 21 8 23 28 3 23 0 27 -23 27
-23 0 -26 -4 -23 -27z"/>
<path d="M1127 1383 c-4 -3 -7 -15 -7 -25 0 -13 7 -18 25 -18 20 0 25 5 25 25
0 18 -5 25 -18 25 -10 0 -22 -3 -25 -7z"/>
<path d="M1210 1345 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1302 1348 c2 -13 12 -22 26 -24 18 -3 22 1 22 21 0 20 -5 25 -26 25
-20 0 -25 -4 -22 -22z"/>
<path d="M1400 1345 c0 -26 7 -29 34 -19 27 10 20 44 -9 44 -20 0 -25 -5 -25
-25z"/>
<path d="M1020 1270 l0 -50 35 0 35 0 0 50 0 50 -35 0 -35 0 0 -50z"/>
<path d="M940 1285 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1120 1285 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1210 1275 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1300 1275 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1400 1275 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
</g>
</svg>
       

    )
}

export default Icon1;