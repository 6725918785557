export const TitleStep2 = {
    RO: {
        title: 'Date de contact'
    },

    EN: {
        title: 'Contacts'
    }
}
export const DataPfStep2 = {
    RO: [
        { label: 'Nume', name: 'name' },
        { label: 'Prenume', name: 'surname' },
        { label: 'Telefon', name: 'phone' },
        { label: 'Email', name: 'email' }
    ],
    EN: [
        { label: 'First Name', name: 'name' },
        { label: 'Second Name', name: 'surname' },
        { label: 'Phone', name: 'phone' },
        { label: 'Email', name: 'email' }
    ]
}
export const DataPjStep2 = {
    RO: [
        { label: 'Nume', name: 'name' },
        { label: 'Prenume', name: 'surname' },
        { label: 'CUI', name: 'cui' },
        { label: 'Telefon', name: 'phone' },
        { label: 'Email', name: 'email' }
    ],
    EN: [
        { label: 'First Name', name: 'name' },
        { label: 'Second Name', name: 'surname' },
        { label: 'Company Name', name: 'company' },
        { label: 'CUI no.', name: 'cui' },
        { label: 'Phone', name: 'phone' },
        { label: 'Email', name: 'email' }
    ]
}
