const Icon5 = ({_width, _height, _color}) => {
    
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width={_width} height={_height} viewBox="0 0 240.000000 240.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)"
fill={_color} stroke="none">
<path d="M1396 2393 c-4 -4 -14 -21 -21 -38 -104 -238 -125 -292 -125 -321 l0
-33 -393 -3 -394 -3 -48 -30 c-33 -20 -56 -44 -71 -75 l-23 -45 -1 -738 0
-738 25 -49 c26 -52 84 -95 141 -105 49 -9 58 5 64 97 5 76 7 83 26 84 51 3
54 44 3 44 l-29 0 0 569 c0 326 -4 580 -9 594 -7 17 -20 26 -46 31 -48 9 -112
68 -125 115 -18 70 15 147 81 186 32 19 52 20 414 20 l380 0 5 -521 c5 -572 2
-543 67 -571 45 -18 157 -17 200 3 67 32 64 1 61 640 l-3 579 -47 110 c-27 60
-58 130 -70 155 -22 43 -45 59 -62 43z m38 -114 c-6 -10 -44 -12 -44 -3 0 3 5
15 11 26 11 21 12 21 25 3 7 -10 11 -22 8 -26z m55 -119 c13 -33 22 -62 20
-64 -11 -11 -183 -8 -187 2 -2 7 8 37 22 67 l25 55 48 0 48 0 24 -60z m-101
-607 c-3 -463 -4 -488 -21 -491 -10 -2 -29 -1 -43 2 l-24 6 0 485 0 485 45 0
45 0 -2 -487z m140 0 l-3 -488 -40 0 -40 0 -3 488 -2 487 45 0 45 0 -2 -487z
m-1058 41 l35 -10 -3 -659 c-2 -363 -5 -661 -7 -663 -6 -7 -71 27 -88 46 -45
50 -46 64 -47 713 0 339 3 618 6 622 4 3 21 -4 38 -17 17 -13 47 -27 66 -32z
m1058 -620 c5 -61 -21 -79 -113 -79 -88 0 -115 17 -115 75 0 20 3 40 7 44 4 3
54 5 112 4 l106 -3 3 -41z"/>
<path d="M1842 2167 c-6 -7 -12 -46 -14 -87 l-3 -75 -90 -5 c-75 -4 -90 -8
-93 -22 -4 -19 -4 -19 103 -23 33 -1 66 -2 73 -3 9 -2 12 -127 12 -587 l0
-585 23 -9 c12 -5 43 -18 69 -28 41 -16 50 -25 69 -67 36 -85 15 -165 -56
-204 -16 -9 -31 -20 -33 -24 -2 -5 -97 -8 -210 -8 l-207 0 -380 380 c-209 209
-387 380 -396 380 -9 0 -20 -7 -23 -16 -9 -24 -7 -1157 2 -1172 7 -10 132 -12
592 -10 545 3 584 4 594 21 9 15 -12 40 -154 181 -91 90 -164 168 -163 173 2
4 86 11 187 14 l184 5 45 37 c25 20 55 57 66 81 21 43 22 49 21 779 0 405 -3
744 -7 754 -13 36 -66 94 -104 113 -44 23 -92 26 -107 7z m96 -57 c19 -12 41
-38 52 -63 19 -40 20 -72 20 -669 0 -346 -3 -628 -8 -628 -4 0 -15 9 -24 19
-9 11 -35 25 -57 32 l-41 12 0 659 c0 520 3 658 13 658 6 0 27 -9 45 -20z
m-670 -1523 c287 -287 522 -526 522 -529 0 -4 -237 -6 -527 -5 l-528 2 -3 528
c-1 290 1 527 5 527 5 0 243 -235 531 -523z"/>
<path d="M860 471 c0 -263 2 -300 16 -305 9 -3 141 -6 294 -6 232 0 280 2 290
15 6 8 10 19 8 25 -2 6 -129 137 -283 290 -200 200 -286 280 -302 280 l-23 0
0 -299z m290 -19 c126 -126 230 -232 230 -235 0 -4 -106 -7 -235 -7 l-235 0 0
235 c0 129 2 235 5 235 3 0 108 -103 235 -228z"/>
</g>
</svg>

       

      
    )
}

export default Icon5;