
import './App.scss';
import Menu from './components/mainMenu/Menu';
import PagesRoutes from './hoc/PagesRoutes';
import ProjectsRoutes from './hoc/ProjectsRoutes';
import Footer from './components/footer/Footer';
import { LanguageSwitcher } from './components/languageSwitcher/LanguageSwitcher';
import { DeviceResolution } from './components/deviceResolution/DeviceResolution';
import { PagesHieracky } from './components/pagesHieracky/PagesHieracky';
import Themes from './components/themes/Themes';

function App() {
  return (

    <div className="mdr-web-content">
      <div id="header">
        <DeviceResolution />
        <LanguageSwitcher />
        <Themes />
        <Menu />
      </div>

      <div id='page-hieracky'><PagesHieracky /></div>
      <div id="content-pages">
        <PagesRoutes />
        <ProjectsRoutes />
      </div>
      <div id="footer"><Footer /></div>
    </div>

  );
}

export default App;
