import ProjectPage from '../pages/js/ProjectPage/ProjectPage';
import ProjectsPageRoutes from './ProjectsPageRoutes'
import { Projects } from '../data/ProjectsData';
import { useSelector } from 'react-redux';

function ProjectRoutes(){

  let [path, list, targetComponent] = ['', [], <ProjectPage/>];
  const language = useSelector((state)=>state.language.language);
  return (
    
    <>
    <ProjectsPageRoutes 
      path = '/projects'
      list = {Projects[language]}
      targetComponent = {targetComponent}
    />
    </>
  )
}

export default ProjectRoutes;
