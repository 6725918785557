import {useRoutes} from 'react-router-dom';
import Home from '../pages/js/Home/Home';
import Services from '../pages/js/Services/Servicii';
import Projects from '../pages/js/Projects/Proiecte';
import Team from '../pages/js/Team/Echipa';
import Contact from '../pages/js/Contact/Contact';
import Blog from '../pages/js/Blog/Blog';
import Configurator from '../pages/js/Configurator/Configurator';

function PagesRoutes(){

  let pagesRoutes = useRoutes([
    {path: '/', element: <Home />},
    {path: '/home', element: <Home />},
    {path: '/services', element: <Services />},
    {path: '/projects', element: <Projects />},
    {path: '/team', element: <Team />},
    {path: '/blog', element: <Blog />},
    {path: '/configurator', element: <Configurator />},
    {path: '/contact', element: <Contact />}
  ]);

  return pagesRoutes;
}

export default PagesRoutes;
