import './step3.tablet.scss';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { addCheckboxIntoForm } from '../../../../../store/actions/configuratorFormChecksActions';
import { setItem } from '../../../../../store/actions/configuratorFormChecksActions';
import Thumb from '../../../../../components/thumb/Thumb';
import { DataStep3 } from '../../../../../data/configurator/step1/dataStep3';

function Step3Tablet() {
    const dispatch = useDispatch();
    let [selectedItem, setSelectedItem] = useState([]);
    let [indexSelectedItem, setIndexSelectedItem] = useState(-1);
    const language = useSelector((state) => state.language.language);
    let [dataStep3, setDataStep3] = useState(DataStep3[language]);
    //cladiri civile
    let constructionsList1 = dataStep3.constructionsList1;
    let constructionsList2 = dataStep3.constructionsList2;
    let constructionsList3 = dataStep3.constructionsList3;
    let constructionsList4 = dataStep3.constructionsList4;

    let data = [];
    data.push(...constructionsList1);
    data.push(...constructionsList2);
    data.push(...constructionsList3);
    data.push(...constructionsList4);

    useEffect(() => {
        if (selectedItem.length === 0) {
            dispatch(addCheckboxIntoForm(data));
            for (const index in data) {
                setSelectedItem(prevArr => [...prevArr, `container-step-3-item-${Number(index)} deselected`]);
            }
        }
        setDataStep3(DataStep3[language]);
    }, [indexSelectedItem, language])

    function selectBuild(item, index) {
        //reset array items, set as `deselected` all elements
        let newSelectedItem = selectedItem;
        for (const index in data) {
            newSelectedItem[index] = `container-step-3-item-${Number(index)} deselected`;
        }
        setSelectedItem(newSelectedItem);
        //set new item as `selected`
        newSelectedItem = selectedItem;
        newSelectedItem[index] = `container-step-3-item-${Number(index)} selected`;
        setSelectedItem(newSelectedItem);
        setIndexSelectedItem(index);
        dispatch(setItem({ step: 'step3', data: data[index] }))
    }
    return (

        <div className='main-step-3-tablet'>
            {/* CIVIL CONSTRUCTIONS */}
            <div className='category-contructions-tablet'>
                <div className='footer-category-tablet'>
                    <div className='title-category-step-3-tablet'><span>Constructie civila</span></div>
                </div>
                {/* civil construction 1 */}
                <div className='subcategory-contructions-tablet'>
                    {data.map((item, index) => {
                        if (item.indexCategory === 1) {

                            return (
                                <div key={Math.random()}>


                                    <Thumb
                                        idClass={selectedItem[index]}
                                        label={item.label}
                                        callback={selectBuild.bind(this, item, index)}
                                    />
                                </div>
                            )
                        }
                    })}
                </div>
                {/* civil construction 2 */}
                <div className='subcategory-contructions-tablet'>
                    {
                        data.map((item, index) => {
                            let idClass;
                            if (item.indexCategory === 2) {
                                if (item.id) {
                                    idClass = (data[index - 1].id !== data[index].id) ?
                                        'break'
                                        : ''
                                }
                                return (
                                    <div key={Math.random()} >
                                        <div className={idClass}> </div>
                                        <Thumb
                                            idClass={selectedItem[index]}
                                            idClassLabel2='category-item-step-3-tablet'
                                            label={item.label}
                                            label2={item.id}
                                            callback={selectBuild.bind(this, item, index)}
                                        />
                                    </div>
                                )
                            }
                        })}
                </div>
            </div>
            {/* INDUSTRIAL CONSTRUCTIONS */}
            <div className='category-contructions-tablet'>
                <div className='footer-category-tablet'>
                    <div className='title-category-step-3-tablet'><span>Constructie industriala</span></div>
                </div>
                <div className='subcategory-contructions-tablet'>
                    {data.map((item, index) => {
                        if (item.indexCategory === 3) {
                            return (
                                <div key={Math.random()}>
                                    <Thumb
                                        idClass={selectedItem[index]}
                                        label={item.label}
                                        callback={selectBuild.bind(this, item, index)}
                                    />
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
            {/* AGROZOOTECHNICAL CONSTRUCTIONS */}
            <div className='category-contructions-tablet'>
                <div className='footer-category-tablet'>
                    <div className='title-category-step-3-tablet'><span>Constructie agrozootehnica</span></div>
                </div>
                <div className='subcategory-contructions-tablet'>
                    {data.map((item, index) => {
                        if (item.indexCategory === 4) {
                            return (
                                <div key={Math.random()}>
                                    <Thumb
                                        idClass={selectedItem[index]}
                                        label={item.label}
                                        callback={selectBuild.bind(this, item, index)}
                                    />
                                </div>
                            )
                        }
                    })}
                </div>

            </div>
        </div>
    )
}
export default Step3Tablet;