import './Configurator.desktop.scss';
import '../stepsCommonStyles.scss';
import Step1Desktop from './step1/step1.desktop';
import Step2Desktop from './step2/step2.desktop';
import Step3Desktop from './step3/step3.desktop';
import Step4Desktop from './step4/step4.desktop';
import { useState, useEffect } from 'react';
import WarningCompleteFields from '../../../../components/warningCompleteFieldsToNextStep/warningCompleteFieldsToNextStep';
import { useSelector } from 'react-redux';
import PagePreview from '../../../../components/PagePreview/PagePreview';
import StatusFieldsForNextStepFct from '../../../../components/warningCompleteFieldsToNextStep/statusFieldsForNextStep';
import { useRef } from 'react';

const ConfiguratorDesktop = ({ sendEmailCallback }) => {
    const TEXT = "Oferta";
    let configuratorCheckBox = useSelector((state) => state.configuratorCheckbox.checkboxList);
    let [statusWarningCompleteFields, setStatusWarningCompleteFields] = useState();
    let [previewPage, setPreviewPage] = useState('preview-page-hide');
    let [containerSteps, setContainerSteps] = useState('container-steps-show');
    let [buttonPreview, setButtonPreview] = useState('Preview');
    let [countClick, setCountClick] = useState(0);
    let [readyToSendMail, setReadyToSendMail] = useState(false);
    const form = useRef();
    let StatusFieldsForNextStep = StatusFieldsForNextStepFct;

    let steps = [
        {
            class: 1,
            component: <Step1Desktop />
        },
        {
            class: 2,
            component: <Step2Desktop />
        },
        {
            class: 3,
            component: <Step3Desktop />
        },
        {
            class: 4,
            component: <Step4Desktop />
        },
    ];
    let totalSteps = steps.length;
    let [selectedStep, setSelectedStep] = useState(-1);
    let [statusDisplayStep, setStatusDisplayStep] = useState('hide-content-tabs');
    let [visibilityLeftArrow, setVisibilityLeftArrow] = useState('inactive-left-arrow');
    let [visibilityRightArrow, setVisibilityRightArrow] = useState('active-right-arrow');
    let [selectTab, setSelectTab] = useState([]);
    let [buttonConfigurator, setButtonConfigurator] = useState('locked-button-configurator');

    let data = [
        {
            stepTitle: 'Step 1',
            description: 'Alege tipul de contract',
            isOpen: true
        },
        {
            stepTitle: 'Step 2',
            description: 'Completeaza date',
            isOpen: false
        },
        {
            stepTitle: 'Step 3',
            description: 'Alege tipul de locuinta',
            isOpen: false
        },
        {
            stepTitle: 'Step 4',
            description: 'Personalizeaza locuinta',
            isOpen: false
        }
    ]

    useEffect(() => {
        if (selectedStep === -1) {
            setSelectedStep(1)
            setStatusDisplayStep('show-content-tabs-1');
        }
        if (previewPage == 'preview-page-hide') {
            if (selectedStep <= 1) {
                setVisibilityLeftArrow('inactive-left-arrow');
                setVisibilityRightArrow('active-right-arrow');
            } else if (selectedStep >= totalSteps) {
                setVisibilityLeftArrow('active-left-arrow');
                setVisibilityRightArrow('inactive-right-arrow');
            } else {
                setVisibilityLeftArrow('active-left-arrow');
                setVisibilityRightArrow('active-right-arrow');
            }
        } else {
            setVisibilityLeftArrow('inactive-left-arrow');
            setVisibilityRightArrow('inactive-right-arrow');
        }

        if (selectTab.length === 0) {
            for (const index in data) {
                (Number(index) === 0) ?
                    setSelectTab(prevArray => [...prevArray, `selected-content-${Number(index) + 1}`])
                    :
                    setSelectTab(prevArray => [...prevArray, `deselected-content-${Number(index) + 1}`]);
            }
        }
        //
        if (configuratorCheckBox.step4) {
            if (configuratorCheckBox.step4.length != 0) {
                setButtonConfigurator('unlocked-button-configurator');
                setReadyToSendMail(true);
            } else {
                setButtonConfigurator('locked-button-configurator')
            }

        } else {
            setButtonConfigurator('locked-button-configurator');
        }

        //setStatusWarningCompleteFields(null);
    }, [selectedStep, statusDisplayStep, configuratorCheckBox, previewPage, readyToSendMail])




    function actionArrow(direction) {

        if (direction === 'left') {
            if (selectedStep > 1) {
                setCountClick(--countClick)

                setSelectedStep(--selectedStep);
                setStatusDisplayStep('show-content-tabs-' + selectedStep);
                setStatusWarningCompleteFields(true);
            }
        } else if (direction === 'right') {
            setCountClick(++countClick)
            let test = true;
            //fields are not selected/filled
            let dataStep = configuratorCheckBox[`step${(selectedStep)}`];
            if (!dataStep) test = false;

            if (selectedStep === 2) test = StatusFieldsForNextStep(dataStep).isReady;

            setStatusWarningCompleteFields(test);

            if (test) {
                //maximum step
                if (selectedStep < totalSteps) {
                    setSelectedStep(++selectedStep);
                }
                setStatusDisplayStep('show-content-tabs-' + selectedStep);
            } else {
                setStatusWarningCompleteFields(false);
                return
            }
        }



        let newSelectedTab = selectTab;
        for (let index = 0; index < data.length; index++) {
            if (index <= selectedStep - 1) {
                newSelectedTab[index] = `selected-content-${index + 1}`;
            } else {
                newSelectedTab[index] = `deselected-content-${index + 1}`;
            }
        }

        setSelectTab(newSelectedTab)
    }

    function showPreview() {
        if (!configuratorCheckBox.step4 || configuratorCheckBox.step4.length === 0) return;
        let idClassPreviewPage = '';
        let idContainerSteps = '';
        let idButtonName = '';
        if (previewPage === 'preview-page-show') {
            idClassPreviewPage = 'preview-page-hide';
            idContainerSteps = 'container-steps-show';
            idButtonName = 'Preview';
        } else {
            idClassPreviewPage = 'preview-page-show';
            idContainerSteps = 'container-steps-hide';
            idButtonName = 'Back';
        }
        setPreviewPage(idClassPreviewPage);
        setContainerSteps(idContainerSteps);
        setButtonPreview(idButtonName);
    }
    return (
        <div>
            <div className='arrows'>
                <span className={visibilityLeftArrow} onClick={actionArrow.bind(this, 'left')}>                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                </svg>
                </span>
                <span className={visibilityRightArrow} onClick={actionArrow.bind(this, 'right')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                    </svg>
                </span>
            </div>
            <div className='container-configurator'>
                <div className='tabs'>
                    {
                        data.map((item, index) => {
                            return (
                                <div key={`tab-${(index + 1)}`}>

                                    <span className={`${selectTab[index]}`}>

                                        <div className='shape-tab-step'></div>
                                        <div className='text-shape-tab-step'>{item.stepTitle}</div>


                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='buttons-configurator'>
                    <div className={buttonConfigurator} onClick={showPreview}>
                        <span>{buttonPreview}</span></div>
                    <div className={buttonConfigurator}>

                        <form ref={form} onSubmit= {readyToSendMail ? sendEmailCallback: ()=>{}}>

                        {readyToSendMail ?
                         <button className='button-send-mail' type="submit"><span className={buttonConfigurator}>
                         <span>Send</span></span></button>
                         :
                         <div className='button-send-mail' type="submit"><span className={buttonConfigurator}>
                         <span>Send</span></span></div>}
                           
                        </form>
                    </div>
                </div>


                <div className={containerSteps}>


                    <div>
                        {
                            data.map((item, index) => {
                                return (
                                    <div key={`step-${(index + 1)}`} className={statusDisplayStep}>
                                        <div>
                                            {steps[index].component}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>


                </div>
                <div>
                    {(statusWarningCompleteFields === false) ? <WarningCompleteFields key={countClick} step={selectedStep} /> : null}
                </div>
                <div className={previewPage}>

                    <PagePreview />

                </div>
            </div>
            
        </div>
    )
}

export default ConfiguratorDesktop;