import './viewBigImageOnScreen.scss';
import ProgressiveImage from '../progressiveImage/ProgressiveImage';
import { useEffect, useState } from 'react';

const ViewBigImageOnScreen = ({data}) => {
    let ia = data.indexImage;

    const idsForBigImage = data.idsForBigImage;
    let [visibilityBigImage, setVisibilityBigImage] = useState('main-view-big-image-on-screen-visible');
    let [leftArrow, setLeftArrow] = useState('arrow-view-big-image left-arrow-view-big-image');
    let [rightArrow, setRightArrow] = useState('arrow-view-big-image right-arrow-view-big-image');
    
    let [indexImage, setIndexImage] = useState(data.indexImage);
    let [pathImage, setPathImage] = useState(data.idsForBigImage.get(data.indexImage).path.pathBigImage);
    let [pathTinyImage, setPathTinyImage] = useState(data.idsForBigImage.get(data.indexImage).path.pathTinyImage);
    let [srcImage, setSrcImage] = useState();
    let [srcImageTiny, setSrcImageTiny] = useState();
    
    
    useEffect(() => {

        import(`../../pages/${pathImage}`).then(module =>  {
            setSrcImage(module.default)
        });  
        import(`../../pages/${pathTinyImage}`).then(module => {
            setSrcImageTiny(module.default);
            //setPathImage('aaaa');
        });  
        
    },[srcImage, pathImage, pathTinyImage])
    function actionArrow(direction) {
        ( direction === 'left') ? setIndexImage(--indexImage) : setIndexImage(++indexImage);
        /*if(indexImage == idsForBigImage.size) setIndexImage(1);
        var a = indexImage;
        if(indexImage <= 1) a = 1;
        setIndexImage(a)*/
        setPathImage(data.idsForBigImage.get(indexImage).path.pathBigImage);
        setPathTinyImage(data.idsForBigImage.get(indexImage).path.pathTinyImage);
    }
    function onCloseBigImage() {
        setVisibilityBigImage('main-view-big-image-on-screen-hidden');
    }
    return (
        <div className={visibilityBigImage} >
             <div className='black-screen' onClick={onCloseBigImage}></div>
            
            <div className='image-view-big-image-on-screen'>
            <div className='arrows-view-big-image-on-screen'>
             <div className={leftArrow} onClick={actionArrow.bind(this, 'left')}>                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                </svg>
                </div>
                <div className={rightArrow} onClick={actionArrow.bind(this, 'right')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                    </svg>
                </div>
             </div>
                <div className='image-view-on-screen'>
                    <span className={'pathImage'}>            
                    {(srcImage)?

                        <ProgressiveImage key={Math.random()}
                                src={srcImage}
                                placeholderSrc={srcImageTiny}
                                className='image-view-on-screen'
                        />:null
                    }    
                </span>

                </div>
            </div>
           
        </div>
    )
}

export default ViewBigImageOnScreen;