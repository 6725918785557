const Icon2 = ({_width, _height, _color}) => {
    
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width={_width} height={_height} viewBox="0 0 240.000000 240.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)"
fill={_color} stroke="none">
<path d="M1918 1901 c-13 -11 -30 -38 -36 -60 -7 -22 -20 -42 -30 -45 -26 -9
-36 -31 -68 -156 l-29 -115 -5 148 c-5 154 -9 166 -52 167 -16 0 -257 -219
-1003 -909 -104 -97 -236 -218 -292 -270 -107 -98 -124 -129 -84 -151 13 -6
253 -10 710 -10 614 0 691 2 705 16 13 13 16 46 16 197 l1 182 25 18 c22 16
30 17 80 6 63 -14 67 -20 79 -114 8 -65 69 -271 87 -292 15 -18 43 -16 63 4
30 30 15 257 -42 619 l-49 311 33 129 c31 121 32 131 18 164 -12 26 -13 43 -6
68 12 40 1 74 -31 96 -30 21 -60 20 -90 -3z m78 -46 c3 -8 1 -38 -6 -65 -11
-46 -10 -52 6 -64 17 -13 16 -19 -15 -144 l-33 -130 51 -339 c39 -257 51 -363
50 -443 l-1 -105 -29 90 c-16 50 -36 132 -44 184 -7 51 -16 95 -18 97 -2 2
-44 15 -94 28 l-90 23 -16 -26 -16 -26 -7 33 c-4 17 -13 32 -20 32 -12 0 -14
-41 -14 -229 l0 -229 -667 0 c-367 -1 -670 1 -675 4 -4 3 23 33 60 67 36 34
103 96 147 137 44 41 130 120 190 176 61 55 288 265 505 465 218 200 406 373
418 383 l22 19 0 -180 0 -179 -96 -125 c-52 -68 -99 -125 -104 -127 -5 -2 -10
28 -12 67 l-3 70 -175 -161 c-96 -88 -226 -208 -287 -265 -62 -58 -124 -114
-138 -125 -14 -11 -25 -24 -25 -29 0 -5 128 -9 315 -9 l315 0 0 94 c0 94 0 94
38 143 53 67 54 70 32 76 -16 4 -33 -11 -79 -71 -65 -84 -201 -223 -201 -205
0 32 118 206 302 444 l206 266 31 119 c39 148 46 164 70 164 14 0 21 9 26 33
14 63 23 77 50 77 14 0 28 -7 31 -15z"/>
<path d="M1702 1265 c-78 -102 -142 -190 -142 -195 0 -6 10 -10 23 -10 15 1
36 19 67 61 l45 61 3 -76 c3 -72 4 -76 26 -76 13 0 29 7 36 15 18 22 30 18 30
-10 0 -20 7 -26 43 -35 23 -6 55 -15 71 -20 55 -18 54 -23 19 208 -18 119 -35
227 -38 240 -11 48 -41 22 -183 -163z"/>
</g>
</svg>

      
    )
}

export default Icon2;