import './PagePreview.scss'
import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

const PagePreview = () => {
  const checkboxList = useSelector((state) => state.configuratorCheckbox.checkboxList);
  const language = useSelector((state) => state.language.language);

  useEffect(() => {

  }, [])
  return (

    <div className='preview-page'>

      <div className='header-preview-page'>
        <div className='title-mdr-preview-page'>MDR</div>
        <div className='title-architecture-preview-page'>architecture</div>
      </div>
      <div className='content-preview-page'>
        <div>Va multumim pentru interesul acordat!</div>
        <div>Mai jos regasiti datele pe care ni le veti primite pentru a realiza o oferta!</div>
        <div className='data-steps-preview'>
          <div className='col-content-preview-1'>
            {
              checkboxList.step2 === null ? console.log('null')
                :
                Object.entries(checkboxList.step2).map((item, index) => {
                  return (
                    <div className='title-preview-step'>
                      <span className='title'>{item[0]}:</span><span>{item[1]}</span>
                    </div>
                  )
                })
            }
          </div>
          <div className='col-content-preview-2'>
            <div className='content-preview-step-3'>
             
                {
                  checkboxList.step3 === null ? console.log('null') : checkboxList.step3.label
                }
              
            </div>
            <div className='content-preview-step'>
              {
                checkboxList.step4 === null ? console.log('null')
                  :
                  checkboxList.step4.map((item, index) => {
               
                    return (
                      <div>
                        
                          <div className='item-preview-step-4'>{item.label}</div>
                       
                      </div>
                    )
                  })}
            </div>
          </div>
        </div>
      </div>



      <div className='footer-preview-page'>
        <div>Email: office@mdr-architecture.com</div>
        <div>Telefon: 076 456 0042</div>
      </div>

    </div>
  )
};

export default PagePreview;