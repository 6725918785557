import './ProjectPage.scss';
import { useSelector } from 'react-redux';
import ProjectPageMobile from './mobile/ProjectPage.mobile';
import ProjectPageTablet from './tablet/ProjectPage.tablet';
import ProjectPageDesktop from './desktop/ProjectPage.desktop';


function Projects() {
  const device = useSelector((state) => state.deviceType.device);

  return (
    <div>
      {
      device === 'mobile' || device === 'mobile_w_max_360'? <ProjectPageMobile/> : 
      device === 'tablet' ? <ProjectPageTablet/> : 
      device === 'desktop' ? <ProjectPageDesktop/> : 
      null
    } 
    </div>
  )
}

export default Projects;
