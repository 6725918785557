
import './Blog.scss';
import { useState, useEffect, useContext } from 'react'
import BlogData from '../../../data/BlogData'
import {Link} from 'react-router-dom';
import ProgressiveImage from '../../../components/progressiveImage/ProgressiveImage';
import { useSelector } from 'react-redux';

function Blog(){

  const language = useSelector((state) => state.language.language).language;
  let [blogData, setBlogData] = useState(BlogData.getContent(language));

  function actionIconContact(link) {

    window.open(link);
  } 

  useEffect(() => {
    setBlogData(BlogData.getContent(language));
    blogData = BlogData.getContent(language);
    
  }, [])

    return (
      <>
              <div className="main-container-blog">
          {
            blogData.map((data, index)=>{

              return(
              <div className="content-article" key = {(index+Math.random()).toString()}>
                  
                    <div className='title-article-border'>
                      <h3 className='title-article'>{data.title}</h3>
                    </div>
                    <div className='image-article'>
                      <ProgressiveImage
                        placeholderSrc = {require(`../../assets/${data.pathImageDescription}`)}
                        src = {require(`../../assets/${data.pathImageDescription}`)}
                        className ='image-description-article'
                      />
                    </div>
                      <h3 className='short-description-article'>{data.shortDescription}</h3>
                    
                    <div className='bg-article'>  
                      
                      
                          <Link  style={{textDecoration: 'none'}} onClick = {actionIconContact.bind(this, data.facebook)} >
                            <span className='read-more-article'>
                              Citeste mai mult
                              </span>
                          </Link>
                          
                    </div>  
              </div>
              )
            })
          }
          
        </div>
        </>

    ) 
}

export default Blog;
