
import './Servicii.desktop.scss';
import { useEffect, useState } from 'react';
import ServicesData from '../../../../data/ServicesData';
import { useSelector } from 'react-redux';

function ServiciiDesktop() {
  const language = useSelector((state) => state.language.language);
  let servicesData = ServicesData.SERVICES_DATA.getAllServices(language);
  let [title, setTitle] = useState("");
  let [descriptionPage, setDescriptionPage] = useState([]);
  let [selectedItem, setSelectedItem] = useState(0);
 



  useEffect(() => {
    if(descriptionPage.length === 0){
      
      for (const index in servicesData) {
        let idClass = (index == 0 ) ? 'description-service-visible' : 'description-service-hide';
        setDescriptionPage(prevArr => [...prevArr, `${idClass} description-service-${index}`]);
      }
    }

  }, [selectedItem, language])

  
 

  function actionClick(index) {
    setSelectedItem(index);
    let newArrDescriptionPage = descriptionPage;
    for (const index in servicesData) {
      newArrDescriptionPage[index] = `description-service-hide description-service-${index}`
      
    }
    newArrDescriptionPage[index] = `description-service-visible description-service-${index}`;
    setDescriptionPage(newArrDescriptionPage);
    
   

  } 
  return (
    <div className='container-services-page'>

      <div className='main-services-page'>

        {servicesData.map((service, index) => {
          return (

            <div key={(index + Math.random()).toString()}>

              <div className='item-title-services-page' onClick={actionClick.bind(this, index)}> 
                <div className='tsitle-service'>{service.title}</div>
              </div>


              
            </div>


          )
        }
        )}
        {
          
        }

      </div>
<div className='container-content-services-page'>
{servicesData.map((service, index) => {
          return (

            <div key={(index + Math.random()).toString()} className={descriptionPage[index]}>


             
                
                  {service.description.map((lineDescription, index) => {
                    return (
                      <div  key={(index + Math.random()).toString()}>
                        <div className='text-description-services'></div>
                        {lineDescription}
                      </div>
                    )
                  })}
              

            </div>


          )
        }
      )}
</div>
    </div>
  )
}

export default ServiciiDesktop;
