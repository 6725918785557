export const TitleStep1 = {
    RO: {
        title: 'Situatia juridica'
    },

    EN: {
        title: 'The legal situation'
    }
}
export const DataStep1 = {
    RO: [
        {
            label: 'Persoana fizica',
            name: 'Persoana fizica',
            type: 'PF'
        },
        {
            label: 'Persoana juridica',
            name: 'Persoana juridica',
            type: 'PJ'
        },

    ],
    EN: [
        {
            label: 'Individual',
            name: 'Individual',
            type: 'PF'
        },
        {
            label: 'Legal person',
            name: 'Legal person',
            type: 'PJ'
        },
    ],
}