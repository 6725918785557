const Icon3 = ({_width, _height, _color}) => {
    
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width={_width} height={_height} viewBox="0 0 240.000000 240.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.16, written by Peter Selinger 2001-2019
</metadata>
<g transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)"
fill={_color} stroke="none">
<path d="M474 2026 c-50 -22 -74 -61 -74 -122 0 -42 5 -59 25 -83 49 -57 136
-65 194 -16 20 18 41 25 69 25 l40 0 -78 -77 c-83 -84 -151 -193 -170 -274 -9
-37 -17 -49 -36 -53 -14 -4 -29 -18 -34 -32 -13 -33 -13 -155 0 -188 9 -26 11
-26 119 -26 90 0 110 3 115 16 3 9 6 59 6 111 0 77 -3 98 -16 108 -15 11 -15
17 9 69 57 125 160 222 301 286 88 40 125 45 155 20 l23 -20 -96 -270 -95
-270 9 -83 c36 -304 37 -321 27 -531 -8 -181 -7 -210 6 -223 13 -13 51 -15
226 -15 116 0 216 4 222 8 9 5 10 60 6 208 -4 147 -2 242 10 356 29 292 31
280 -70 558 -49 134 -91 250 -94 258 -3 8 1 14 10 14 9 0 20 7 25 17 8 14 15
15 56 4 166 -41 338 -185 392 -326 16 -42 16 -47 0 -63 -20 -23 -24 -200 -4
-220 18 -18 212 -16 227 2 8 9 11 48 9 112 l-3 99 -32 14 c-22 10 -33 22 -33
35 -1 31 -52 146 -90 200 -34 51 -71 91 -133 144 l-38 32 46 0 c35 0 52 -6 73
-25 61 -57 177 -33 209 43 18 42 13 101 -12 138 -38 59 -153 69 -200 18 -22
-24 -23 -24 -236 -24 -213 0 -214 0 -224 23 -9 20 -18 22 -100 25 -107 3 -131
-1 -138 -28 -5 -19 -13 -20 -220 -20 -187 0 -216 2 -228 17 -17 20 -71 43
-101 43 -13 -1 -37 -7 -54 -14z m109 -76 l35 -30 256 0 256 0 0 25 c0 24 2 25
70 25 68 0 70 -1 70 -25 l0 -25 257 0 257 0 21 25 c29 36 73 42 108 15 35 -28
36 -72 2 -105 -33 -34 -67 -32 -102 5 l-28 30 -162 0 c-127 0 -163 -3 -163
-13 0 -7 16 -19 35 -26 60 -21 182 -109 235 -169 58 -65 117 -176 135 -254 10
-46 15 -53 38 -56 26 -3 27 -5 27 -73 l0 -69 -65 0 -65 0 0 70 c0 65 2 70 21
70 20 0 21 4 15 48 -18 134 -152 303 -302 380 -124 64 -254 91 -268 57 -8 -21
-136 -22 -136 -1 0 25 -16 27 -89 11 -222 -46 -411 -208 -471 -403 -25 -81
-25 -90 3 -94 20 -3 22 -8 22 -68 l0 -65 -65 0 -65 0 -3 68 -3 67 28 0 c26 0
29 5 41 53 41 174 190 347 363 424 22 9 39 23 39 30 0 10 -35 13 -161 13
l-161 0 -28 -30 c-15 -16 -38 -30 -50 -30 -25 0 -80 51 -80 75 0 23 54 75 79
75 11 0 36 -13 54 -30z m571 -715 c-12 -24 -13 -36 -5 -58 10 -25 15 -28 48
-25 31 2 39 8 47 31 7 20 7 34 -2 47 -8 14 -12 86 -11 247 l0 228 35 -100 c20
-55 58 -163 86 -240 56 -156 53 -118 27 -407 l-12 -138 -167 0 -166 0 -21 201
-21 202 50 141 c28 77 67 187 87 242 l36 102 3 -222 c2 -187 0 -226 -14 -251z
m210 -477 c3 -13 7 -92 9 -175 l5 -153 -181 0 -180 0 7 167 c4 92 10 171 12
175 3 5 77 8 164 8 157 0 160 0 164 -22z"/>
</g>
</svg>

      
    )
}

export default Icon3;