export const TitleStep4 = {
    RO: {
        title: 'Faze proiectare'
    },

    EN: {
        title: 'Design phases'
    }
}

export const DataStep4 = {
    RO: [
        {
            label: 'DTAC',
            name: 'DTAC',
            detail: ' Documentatie Tehnica pentru obtinerea Autorizatiei de Construire si se refera la proiectul de arhitectură, rezistență și instalații, în baza căruia primăria emite autorizația de construire. Documentația tehnică se realizează de specialiști, arhitecți, ingineri structuriști, ingineri de instalații, si este verificată de către verificatori de proiect.'
        },
        {
            label: 'PTh',
            name: 'PTh',
            detail: ' Proiect Tehnic și reprezintă totalitatea documentelor, planurilor furnizate de către arhitecți, ingineri de rezistență, ingineri de instalații, care cuprinde soluţiile tehnice şi economice de realizare a obiectivului de investiţii, pe baza căruia se execută lucrările de construcţii autorizate.'
        },
        {
            label: 'DDE',
            name: 'DDE',
            detail: ' Detalii De Executie, parte din Proiectului Tehnic (PT), documentația conține informații detaliate despre soluțiile tehnice de alcătuire, executare, montare și alte operațiuni necesare pentru construcția părților și elementelor de construcție sau instalații aferente, utila pentru lamurirea anumitor detalii constructive astfel incat constructia sa indeplineasca toti parametrii de conformitate cu proiectul.'
        },
        {
            label: 'DTAD',
            name: 'DTAD',
            detail: ' Documentatie  Tehnica pentru obtinerea Autorizatiei de Demolare si face referire la proiectul intocmit de catre echipa de proiectare compusa din arhitect, inginer de rezistenta si expert tehnic, persoana abilitata sa efectueze expertiza tehnica a constructiei existente.'
        },
        {
            label: 'DTOE',
            name: 'DTOE',
            detail: ' Documentației Tehnice pentru Organizarea Execuției și este necesară în cadrul proiectelor ce prevăd organizări de șantier necesare acestuia.'
        },
    ],
    EN: [
        {
            label: 'DTCA',
            name: 'DTCA',
            detail: ' Technical Documentation for obtaining the Construction Authorization and refers to the architecture, resistance and installations project, based on the primary act of issuing the construction authorization. The technical documentation is made by specialists, architects, structural engineers, installation engineers, and is checked by the project verifiers.'
        },
        {
            label: 'TP',
            name: 'TP',
            detail: ' Technical Project and represents all the documents, plans provided by architects, resistance engineers, installation engineers, which includes the technical and economic solutions to achieve the investment objective, based on which the authorized construction works are executed.'
        },
        {
            label: 'ED',
            name: 'ED',
            detail: ' Execution Details, part of the Technical Project (PT), the documentation contains detailed information about the technical solutions for composition, execution, assembly and other operations necessary for the construction of parts and construction elements or related installations, useful for clarifying certain construction details so that its construction fulfill all the parameters of compliance with the project.'
        },
        {
            label: 'TDDA',
            name: 'TDDA',
            detail: ' Technical Documentation for obtaining the Demolition Authorization and refers to the project drawn up by the design team composed of the architect, resistance engineer and technical expert, the person qualified to carry out the technical expertise of the existing construction.'
        },
        {
            label: 'TDOE',
            name: 'TDOE',
            detail: ' Technical Documentation for the Organization of Execution and is required in projects that provide for site organization necessary for it.'
        },
    ]
}
