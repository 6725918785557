import { createReducer } from "@reduxjs/toolkit";
import { setDevice } from "../actions/deviceTypeActions";

const initialState = {
    device: ''
}

const deviceTypeReducer = createReducer(initialState, (builder) => 
    builder
        .addCase(setDevice, (state, action) => {
            state.device = action.payload;
        })
);

export default deviceTypeReducer;