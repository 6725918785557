import { createReducer } from "@reduxjs/toolkit";
import { setLanguage } from "../actions/languageActions";

const initialState = {
    language: 'RO'
}

const languageReducer = createReducer(initialState, (builder)=>{
    builder
        .addCase(setLanguage, (state, action) => {
            state.language = action.payload;
        })
});

export default languageReducer;