import { useEffect , useState} from "react";

const PROGRESSIVE_IMAGE = ({placeholderSrc, src, ...props}) => {

const [srcImg, setSrcImg] = useState(placeholderSrc || src);
   
    useEffect(() => {
       
        const img = new Image();
        img.src = src;
        img.onload = () => {
           
            setSrcImg(src);
        }
    }, [srcImg]

    )
    return(
        
        <>

            <img 
                {...{src:srcImg, ...props}} 
                alt={props.alt || ""}
                className={props.className || ""}
            />
        </>
    );
};

export default PROGRESSIVE_IMAGE;