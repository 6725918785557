
const StatusFieldsForNextStep = (dataStep) => {
    
    let data = {
        isReady: true,
        fieldIssue: ''
    };

    if (dataStep) {
       
        //name fiels
        if (dataStep.name) {
            let nameContainsNumbers = false;
            for (const letter of dataStep.name.split('')) {
                if (!isNaN(letter)) nameContainsNumbers = true;
            }
            if (nameContainsNumbers){
                data.isReady = false; 
                data.fieldIssue = 'name'; 
                return data;
            }
        }else{
            data.isReady = false; 
            data.fieldIssue = 'name';
            return data;
        }
        //surname fiels
        if ((dataStep).surname) {
            let nameContainsNumbers = false;
            for (const letter of dataStep.surname.split('')) {
                if (!isNaN(letter)) nameContainsNumbers = true;
            }
            if (nameContainsNumbers){

                data.isReady = false;  
                data.fieldIssue = 'surname';
                return data;
            } 
        }else{
            data.isReady = false;  
            data.fieldIssue = 'surname';
            return data;
        }
        //company fiels
        if (dataStep.company) {

        }
        //cui field
        if (dataStep.cui) {

        }
        //phone field
        if (dataStep.phone) {
            
            if (isNaN(dataStep.phone)){
                data.isReady = false;  
                data.fieldIssue = 'phone';
                return data;
            } 
        }else{
            data.isReady = false;  
            data.fieldIssue = 'phone';
            return data;
        }
        //email field
        if (dataStep.email) {
            var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            data.isReady = re.test(dataStep.email);
            if(!data.isReady){

                data.fieldIssue = 'email';
                return data;
            }else{
                return data;
            }  
        }else{
            data.isReady = false;  
            data.fieldIssue = 'email';
            return data;
        }
    } else {
        return data = {
            isReady: false,
            fieldIssue: ''
        };;
    }
    
}

export default StatusFieldsForNextStep;