
import './Menu.scss';
import { useSelector } from 'react-redux';

import MenuDesktop from './Menu.desktop';
import MenuMobile from './Menu.mobile';
import MenuTablet from './Menu.tablet';


function Menu() {
  const device = useSelector((state) => state.deviceType.device);

  return (
    <div>
      {
      device === 'mobile' || device === 'mobile_w_max_360' ? <MenuMobile/> : 
      device === 'tablet' ? <MenuTablet/> : 
      device === 'desktop' ? <MenuDesktop/> : 
      null
    } 
    </div>
  )
}

export default Menu;
