const Icon4 = ({_width, _height, _color}) => {
    
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width={_width} height={_height} viewBox="0 0 240.000000 240.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)"
fill={_color} stroke="none">
<path d="M1508 2038 c-2 -2 -5 -232 -8 -513 l-5 -510 -418 -3 c-391 -2 -418
-3 -415 -20 3 -16 44 -17 631 -20 l627 -2 0 -34 c0 -19 -9 -45 -21 -60 l-20
-26 -605 0 c-530 0 -604 -2 -604 -15 0 -8 8 -15 18 -15 9 -1 103 -1 207 0 105
0 196 -4 203 -8 9 -6 12 -36 10 -111 l-3 -103 -75 -1 c-59 -2 -75 -5 -75 -17
0 -20 85 -26 333 -22 176 3 193 4 190 20 -3 14 -16 17 -71 18 -37 1 -70 3 -72
5 -2 3 -5 51 -6 109 l-2 104 277 2 c211 1 283 4 302 15 53 28 54 35 54 478 0
242 -4 420 -10 435 -11 30 -67 68 -94 64 -33 -5 -29 -26 8 -42 19 -8 39 -23
45 -34 7 -14 11 -139 11 -372 l0 -351 -87 3 -88 3 -5 510 -5 510 -112 3 c-62
1 -114 1 -115 0z m199 -517 c0 -266 -1 -490 -4 -498 -4 -9 -28 -13 -84 -13
l-79 0 0 45 0 45 40 0 c29 0 40 4 40 15 0 9 -10 15 -22 16 -13 1 -30 2 -38 3
-10 1 -16 14 -18 43 l-3 42 38 3 c52 4 53 32 1 36 -37 3 -38 4 -38 42 0 38 1
39 38 42 25 2 37 8 37 18 0 10 -12 16 -37 18 -38 3 -38 4 -38 48 l0 44 40 0
c29 0 40 4 40 15 0 11 -11 15 -40 15 l-40 0 0 44 c0 44 0 45 38 48 25 2 37 8
37 18 0 10 -12 16 -37 18 -38 3 -38 4 -38 48 l0 44 40 0 c29 0 40 4 40 15 0
11 -11 15 -40 15 l-40 0 0 49 0 50 38 3 c25 2 37 8 37 18 0 10 -12 16 -37 18
l-38 3 0 60 0 60 83 -3 82 -3 2 -484z m-417 -810 l0 -109 -70 -4 -70 -3 0 113
0 112 70 0 70 0 0 -109z"/>
<path d="M523 1791 c-40 -25 -55 -73 -51 -161 2 -50 7 -65 18 -65 12 0 16 16
18 70 3 90 8 111 29 121 10 5 214 11 453 14 378 4 435 7 435 20 0 13 -56 15
-435 18 -416 2 -436 1 -467 -17z"/>
<path d="M767 1554 c-20 -20 11 -24 204 -24 164 0 210 3 207 13 -4 9 -55 13
-204 15 -110 1 -203 -1 -207 -4z"/>
<path d="M508 1498 c-8 -7 -27 -61 -41 -119 -27 -106 -27 -108 -27 -535 -1
-398 0 -430 17 -452 28 -37 93 -39 128 -4 l26 25 -3 441 -3 441 -29 95 c-32
109 -44 127 -68 108z m41 -195 c1 -18 -5 -23 -23 -23 -34 0 -36 7 -18 65 l15
50 13 -35 c7 -19 13 -45 13 -57z m9 -415 c2 -339 2 -346 -18 -351 -11 -3 -29
-3 -40 0 -20 5 -20 14 -20 348 0 375 -3 356 55 350 20 -2 20 -10 23 -347z m0
-429 c-6 -59 -71 -66 -74 -8 -2 34 3 39 42 39 34 0 35 -2 32 -31z"/>
<path d="M760 1425 c0 -13 27 -15 207 -13 154 2 207 6 211 16 3 9 -43 12 -207
12 -182 0 -211 -2 -211 -15z"/>
<path d="M754 1319 c-15 -25 20 -29 217 -27 185 3 204 5 207 21 3 16 -12 17
-207 17 -140 0 -212 -4 -217 -11z"/>
<path d="M754 1209 c-13 -22 24 -29 151 -27 114 3 130 5 133 21 3 15 -9 17
-137 17 -92 0 -142 -4 -147 -11z"/>
<path d="M1511 921 c-24 -15 -1 -31 45 -31 31 0 44 4 44 14 0 22 -62 34 -89
17z"/>
<path d="M1673 923 c-7 -3 -13 -11 -13 -19 0 -11 16 -14 69 -14 69 0 98 13 70
31 -14 9 -102 10 -126 2z"/>
</g>
</svg>

      
    )
}

export default Icon4;