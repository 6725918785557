import './Home.desktop.scss';
import { useEffect, useState } from "react";
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-browser-router';
import HomeData from '../../../../data/HomeData';
import cover from '../../../assets/page-home/cover.jpg';
import cover_tiny from '../../../assets/page-home/cover_tiny.jpg';
import imageOver1 from '../../../assets/page-home/cover_over_tiny_1.png';
import Progressive_Image from '../../../../components/progressiveImage/ProgressiveImage';
import Icon1 from '../../../../components/svg/icon1';
import Icon2 from '../../../../components/svg/icon2';
import Icon3 from '../../../../components/svg/icon3';
import Icon4 from '../../../../components/svg/icon4';
import Icon5 from '../../../../components/svg/icon5';
import Icon6 from '../../../../components/svg/icon6';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

function HomeDesktop() {

  const device = useSelector((state) => state.deviceType.device);
  const language = useSelector((state) => state.language.language);
  //carousel
  const [mainText, setMainText] = useState(HomeData.getHome(device, language));
  let [statusHoverCover, setStatusHoverCover] = useState(false);
  let [hoverCoverImage, setHoverCoverImage] = useState('item-carousel-cover-home-page-hide-hover');



  function test1() {
    var var_1 = 100;
  }

  let drinks = ['wine', 'beer', 'soda', 'whiskey', 'soda', 'wine'];

  const checkThis = checkDuplicate()
  const noDuplicateDrinks = drinks.filter(checkThis);

  function checkDuplicate(item) {
    let auxDrinks = [];

    return function checkTheIDs(item) {
      let passTheTest = true;
      if (auxDrinks.length === 0) {
        auxDrinks.push(item);
      } else {
        for (const elem of auxDrinks) {
          if (item === elem) passTheTest = false;
        }
      }
      if (passTheTest) auxDrinks.push(item);
      return passTheTest;
    }
  }


  function onMouseOver() {
    //if(statusHoverCover) return;  
    setStatusHoverCover(true);
    setHoverCoverImage('item-carousel-cover-home-page-visible-hover');
  }

  function onMouseOut() {
    // if(!statusHoverCover) return;  
    setStatusHoverCover(false);
    setHoverCoverImage('item-carousel-cover-home-page-hide-hover');
  }

  useEffect(() => {
    setMainText(HomeData.getHome(device, language));
    document.body.classList.add('home-layout')
    return () => {
      document.body.classList.remove('home-layout')
    }
  }, [language, device, statusHoverCover])

  const onActionGadgetBtn = (index) => {

  };
  return (
    <div className="main-page">

      <div className='cover'>

        <div className='description-cover-home-page'>
          <div>{mainText}</div>
        </div>
        <div className='logo-cover-home-page'>
          <div>MDR</div>
          <div className='text-architecture-logo-cover-home-page'>architecture</div>
        </div>
        <div className='gadget-cover-desktop'>
          <Link to={'/projects/project_0'} style={{ textDecoration: 'none' }}>
            <div className='item-gadget-cover-desktop'><Icon1 _width={'60px'} _height={'60px'} _color={'#ffffff'} /></div>
          </Link>
          <Link to={'/projects/project_1'} style={{ textDecoration: 'none' }}>
            <div className='item-gadget-cover-desktop'><Icon2 _width={'60px'} _height={'60px'} _color={'#ffffff'} /></div>
          </Link>
          <Link to={'/projects/project_2'} style={{ textDecoration: 'none' }}>
            <div className='item-gadget-cover-desktop'><Icon3 _width={'60px'} _height={'60px'} _color={'#ffffff'} /></div>
          </Link>
          <Link to={'/projects/project_3'} style={{ textDecoration: 'none' }}>
            <div className='item-gadget-cover-desktop'><Icon4 _width={'60px'} _height={'60px'} _color={'#ffffff'} /></div>
          </Link>
          <Link to={'/projects/project_4'} style={{ textDecoration: 'none' }}>
            <div className='item-gadget-cover-desktop'><Icon5 _width={'48px'} _height={'48px'} _color={'#ffffff'} /></div>
          </Link>
          <Link to={'/projects/project_5'} style={{ textDecoration: 'none' }}>
            <div className='item-gadget-cover-desktop'><Icon6 _width={'60px'} _height={'60px'} _color={'#ffffff'} /></div>
          </Link>


        </div>
        <div className='carousel-cover-home-page' onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
          <div className={hoverCoverImage}>
            <img
              {...{ src: imageOver1 }}
              alt={""}
              className={hoverCoverImage}
            />
          </div>
          <div className='item-carousel-cover-home-page' >
            <Progressive_Image
              src={cover}
              placeholderSrc={cover_tiny}
              className={'item-carousel-cover-home-page'}
            />

          </div>
        </div>

        <div className='photos-cover'>

        </div>


      </div>
    </div>
  )
}

export default HomeDesktop;
