import './Thumb.scss';
const Thumb = ({ idClass, label, label2, idClassLabel2, callback }) => {
    return (
        <div>
            <div className={idClass} onClick={callback}>
                <div>{label}</div>
                <div className={idClassLabel2}>{label2}</div>
            </div>
        </div>
    )
}

export default Thumb;