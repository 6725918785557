import LanguageSwitcherData from './LanguageSwitcherData';

const LANGUAGE_SWITCHER_DATA = (()=>{
    

    const LANGUAGE_SWITCHER = {
        RO:{
            menu:[
                {id:'Acasa', route:'home'},
                {id:'Servicii', route:'services'},
                {id:'Proiecte', route:'projects'},
                {id:'Echipa', route:'team'},
                {id:'Contact', route:'contact'},
            ],
        },

        EN:{
            menu:[
                {id:'Home', route:'home'},
                {id:'Services', route:'services'},
                {id:'Projects', route:'projects'},
                {id:'Team', route:'team'},
                {id:'Contact', route:'contact'},
            ]
        }
    }

    function getDataLanguage(language){
        return LANGUAGE_SWITCHER[language];
    }

    return {
        getDataLanguage:getDataLanguage
    }
})();

export default LANGUAGE_SWITCHER_DATA;