const TEAM_DATA = (() => {

    const TEAM_PAGE_TITLE = {
        RO: {
            title: 'Echipa'
        },

        EN: {
            title: 'Team'
        }
    }
    const TEAM_CATEGORIES = {
        architects: 'architect',
        it: 'it',
        contributors: 'contributor'
    }

    const TEAM = {
        RO: [
            //atchitects
            {
                category: TEAM_CATEGORIES.architects,
                name: "Roxana Daroga",
                roll: "Arhitect",
                iconID: "roxana",
                studies: "Fac. Arhitectura Ion Mincu, Bucuresti",
                facebook: 'https://www.facebook.com/MDR-Architecture-104992505667711',
                instagram: "https://www.instagram.com/m.d.r.architecture/",
                linkedin: "lin",
                description: []
            },
            {
                category: TEAM_CATEGORIES.architects,
                name: "Iulia Topliceanu",
                roll: "Arhitect",
                iconID: "iulia",
                studies: "Fac. Arhitectura Ion Mincu, Bucuresti",
                facebook: 'https://www.facebook.com/MDR-Architecture-104992505667711',
                instagram: "https://www.instagram.com/m.d.r.architecture/",
                linkedin: "lin",
                description: []
            },
            //it
            {
                category: TEAM_CATEGORIES.it,
                name: "Madalin Daroga",
                roll: "IT Support",
                iconID: "madalin",
                studies: "Fac. Matematica Informatica, Brasov",
                facebook: "https://www.facebook.com/MDR-Architecture-104992505667711",
                instagram: "https://www.instagram.com/m.d.r.architecture/",
                linkedin: "https://www.linkedin.com/in/madalin-daroga-b0073955/",
                description: []
            },
            //contributors
            /*{
                category: TEAM_CATEGORIES.contributors,
                name: "Andreea Popa",
                roll: "Colaborator",
                iconID: "andreeapopa",
                studies: "Fac. Arhitectura Ion Mincu, Bucuresti",
                facebook: "https://www.facebook.com/MDR-Architecture-104992505667711",
                instagram: "https://www.instagram.com/m.d.r.architecture/",
                linkedin: "https://www.linkedin.com/in/madalin-daroga-b0073955/",
                description: []
            },*/
        ],
        EN: [
            {
                category: TEAM_CATEGORIES.architects,
                name: "Roxana Tudose",
                roll: "Architect",
                iconID: "roxana",
                studies: "Ion Mincu University of Architecture and Urbanism, Bucharest",
                facebook: 'https://www.facebook.com/MDR-Architecture-104992505667711',
                instagram: "https://www.instagram.com/m.d.r.architecture/",
                linkedin: "lin",
                description: []
            },
            {
                category: TEAM_CATEGORIES.architects,
                name: "Iulia Topliceanu",
                roll: "Architect",
                iconID: "iulia",
                studies: "Ion Mincu University of Architecture and Urbanism, Bucharest",
                facebook: 'https://www.facebook.com/MDR-Architecture-104992505667711',
                instagram: "https://www.instagram.com/m.d.r.architecture/",
                linkedin: "lin",
                description: []
            },
            {
                category: TEAM_CATEGORIES.it,
                name: "Madalin Daroga",
                roll: "IT Support",
                iconID: "madalin",
                studies: "Faculty of Mathematics and Computer Science, Brasov",
                facebook: "https://www.facebook.com/MDR-Architecture-104992505667711",
                instagram: "https://www.instagram.com/m.d.r.architecture/",
                linkedin: "https://www.linkedin.com/in/madalin-daroga-b0073955/",
                description: []
            },
            //contributors
           /* {
                category: TEAM_CATEGORIES.contributors,
                name: "Andreea Popa",
                roll: "Contributor",
                iconID: "andreeapopa",
                studies: "Ion Mincu University of Architecture and Urbanism, Bucharest",
                facebook: "https://www.facebook.com/MDR-Architecture-104992505667711",
                instagram: "https://www.instagram.com/m.d.r.architecture/",
                linkedin: "https://www.linkedin.com/in/madalin-daroga-b0073955/",
                description: []
            },*/
        ]
    }

    function getTeam(language) {
        return TEAM[language];
    }
   
    function getTeamCategories() {
        return TEAM_CATEGORIES;
    }

    function getTitlePage(language) {
        if (TEAM_PAGE_TITLE[language]) return TEAM_PAGE_TITLE[language]['title'];
    }

    return {
        getTeam: getTeam,
        getTitlePage: getTitlePage,
        getTeamCategories:getTeamCategories
    }
})();

export default TEAM_DATA;