import { useState } from 'react';
import { useRoutes } from 'react-router-dom';

function ProjectsPageRoutes({path, list, ...component}){
  let [listRoutes, addRoute] = useState([]);
  let param = '';
  addRoute = (route) => {
    
    listRoutes.push({path: path+'/:'+route, element: component.targetComponent});
    listRoutes.push({path: path+'/'+route+'/:'+route, element: component.targetComponent});
  }

  if(listRoutes.length === 0){
    for (const index in list) {
      param = 'project_'+index;
      
      addRoute(param);
    }
  }
  
  let pagesRoutesComponent = useRoutes(listRoutes);
  return pagesRoutesComponent;
}

export default ProjectsPageRoutes;
