import LanguageSwitcherData from './LanguageSwitcherData';

const SERVICES_DATA = (()=>{

    const SERVICES_TITLE = {
        RO:{
            title:'Servicii'
        },

        EN:{
            title:'Services'
        }
    }

    const SERVICES = {

        RO:[
            {
                title:"Arhitectura",
                description: [
                    ["Servicii arhitectura pentru spatii rezidentiale, business, industrial, horeca."],    
                    ["Proiecte pentru autorizare."],    
                    ["Concept arhitectura."],    
                    ["DTAC-Documentatie Tehnica pentru Autorizare si Construire."],    
                    ["Proiecte de executie"],    
                    ["Proiecte Tehnice si Detalii de executie."],    
                    ["Asistenta tehnica."],    
                ]
            },
            {
                title:"Design Interior",
                description: [
                    ["Oferim servicii design interior pentru spatii rezidentiale, industriale,"],    
                    ["abordand cele mai noi tendinte din domeniu."]    
                ]
            },
            {
                title:"Releveu",
                description: [
                    ["Masuratori, desen 2D si reprezentarea la scara a elementelor unei constructii."],    
                    ["Realizam relevee atat pentru un spatii de birouri, spatii comerciale, pentru locuinte/apartamente,"],    
                    ["cladiri agricole, sau alte tipuri de imobile."]    
                ]
            },
            {
                title:"Randari",
                description: [
                    ["Randari arhitecturale interioare."],    
                    ["Randari arhitecturale exterioare."],    
                    ["Filme de prezentare."],    
                    ["Planuri si elevatii randare pentru prezentari."]    
                ]
            }
        ],
        EN:[
            {
                title:"Architecture",
                description: [
                    ["Architectural services for residential, business, industrial, catering spaces."],    
                    ["Projects for authorization."],    
                    ["Architecture concept."],    
                    ["Technical Documentation for Construction Authorization."],    
                    ["Implementation projects."],    
                    ["Technical Projects & Execution Details - PT-DE."],    
                    ["Technical support."],    
                ]
            },
            {
                title:"Interior Design",
                description: [
                    ["We offer interior design services for residential, business and industrial spaces,"],    
                    ["addressing the latest trends in the field."]    
                ]
            },
            {
                title:"Architectural Survey",
                description: [
                    ["Measurements, 2D drawing and the scale representation of the elements of a building."]    
                ]
            },
            {
                title:"Viewing and Rendering",
                description: [
                    ["Interior architectural renderings."],    
                    ["Exterior architectural renderings."],    
                    ["Presentation films."],    
                    ["Rendered plans and elevations for presentations."]    
                ]
            }
        ]
    }

    function getAllServices(language){
        if(SERVICES[language]) return SERVICES[language];
        
    }

    function getServices(indexService, language){
        if(SERVICES[language]) return SERVICES[language][indexService];
    }

    function getTitlePage(language){
        if(SERVICES_TITLE[language])  return SERVICES_TITLE[language]['title'];
    }

    return {
        getAllServices:getAllServices,
        getServices:getServices,
        getTitlePage:getTitlePage,
    }
})();

export default{SERVICES_DATA}