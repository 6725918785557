import { Link } from "react-browser-router";

const ImageWitchAnimationType1 = ({data}) => {
    return(
        <div>
            <Link to={data.targetProject}
                  style={{ textDecoration: 'none' }}
                  >
                  {data.content}
                </Link>
        </div>
    )
}

export default ImageWitchAnimationType1;