import LanguageSwitcherData from './LanguageSwitcherData';

const MENU_DATA = (()=>{

    const MENU_DATA = {
        RO:[
                {id:'Acasa', route:'home'},
                {id:'Servicii', route:'services'},
                {id:'Proiecte', route:'projects'},
                {id:'Echipa', route:'team'},
                /*{id:'Blog', route:'blog'},*/
                {id:'Oferta', route:'configurator'},
                {id:'Contact', route:'contact'}
        ],

        EN:[
                {id:'Home', route:'home'},
                {id:'Services', route:'services'},
                {id:'Projects', route:'projects'},
                {id:'Team', route:'team'},
                /*{id:'Blog', route:'blog'},*/
                {id:'Oferta', route:'configurator'},
                {id:'Contact', route:'contact'}
        ]
    }

    function getDataMenu(language){
        return MENU_DATA[language];
    }

    function getIdOnChangeLanguage(oldID, language){
        let [newID, route] = ['', ''];
        for (const key in MENU_DATA) {
            for (const elem of MENU_DATA[key]) {
                if(elem.id == oldID) route = elem.route;
            }
        }

        for (const index in MENU_DATA[language]) {
            let elem = MENU_DATA[language][index];
            if(elem.route == route) newID = elem.id;
            
        }
        return newID;
    }

    function getUpdatedTitlePage(currentTitle, language){
        let routeTarget = '';
        for (const elem in MENU_DATA) {
            for (const elemData of MENU_DATA[elem]) {
                if(currentTitle === elemData.id) routeTarget = elemData.route;
            }
        }

        let data = MENU_DATA[language];
        if(!data) return;
        for (const elem of data) {
            if(elem.route === routeTarget){
                return elem.id;
            }
        }
    }

    return {
        getDataMenu:getDataMenu,
        getUpdatedTitlePage:getUpdatedTitlePage,
        getIdOnChangeLanguage:getIdOnChangeLanguage,
    }
})();

export default MENU_DATA;