
import './LanguageSwitcher.scss';
import { useState, createContext} from 'react';
import { Link} from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { setLanguage } from '../../store/actions/languageActions';
import { useSelector } from 'react-redux';


function LanguageSwitcher (){
  
    const dispatch = useDispatch();
  let [textL, setTextL] = useState('');

  const lang = useSelector((state) => state.language.language);
  

  const onClick = (idLanguage) =>{
    setTextL(idLanguage);
    dispatch(setLanguage(idLanguage));
  }

  return (
    <>
      <div className='main-container-language-switcher'>
        
          <div className='language-btn'>
            <Link className='link-language-btn' style={{textDecoration: 'none'}} onClick = {onClick.bind(this, 'RO')}>
              <span className='language-btn-text'>
                RO
              </span>
            </Link>
          </div>
          <div className='language-btn'>
            <Link  className='link-language-btn' style={{textDecoration: 'none'}} onClick = {onClick.bind(this, 'EN')}>
              <span className='language-btn-text'>
                EN
              </span>
            </Link>
          </div>
        
      </div>
      </>
  );
}

export {LanguageSwitcher};
