import { createReducer } from "@reduxjs/toolkit";
import { setFirstTitle } from "../actions/hierackyActions";
import { setSecondTitle } from "../actions/hierackyActions";
import { setThirdTitle } from "../actions/hierackyActions";
import { setIndexProject } from "../actions/hierackyActions";

const initialState = {
    firstTitle: '',
    secondTitle: '',
    thirdTitle: '',
    indexProject: null
}

const hierackyReducer = createReducer(initialState, (builder)=>
    builder
        .addCase(setFirstTitle, (state, action)=>{
            state.firstTitle = action.payload;
        })
        .addCase(setSecondTitle, (state, action)=>{
            state.secondTitle = action.payload;
        })
        .addCase(setThirdTitle, (state, action)=>{
            state.thirdTitle = action.payload;
        })
        .addCase(setIndexProject, (state, action)=>{
            state.indexProject = action.payload;
        })
)

export default hierackyReducer;