import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./reducers/languageReducer";
import deviceTypeReducer from "./reducers/deviceTypeReducer";
import hierackyReducer from "./reducers/hierackyReducer";
import configuratorCheckbox from "./reducers/configuratorFormChecksReducer";

const store = configureStore({
    reducer:{
        language : languageReducer,
        deviceType : deviceTypeReducer,
        hierackyReducer : hierackyReducer,
        configuratorCheckbox : configuratorCheckbox,
    }
})

export default store;