import './step2.desktop.scss';

import { useEffect } from 'react';
import { setItem } from '../../../../../store/actions/configuratorFormChecksActions';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';

import { DataPfStep2 } from '../../../../../data/configurator/step1/dataStep2';
import { DataPjStep2 } from '../../../../../data/configurator/step1/dataStep2';

function Step2Desktop() {
    const dispatch = useDispatch();
    const selectedDataStep1 = useSelector((state) => state.configuratorCheckbox.checkboxList.step1);
    const language = useSelector((state) => state.language.language);
    let [valueFields, setValueFields] = useState([]);
    let [dataStep2, setDataStep2] = useState(DataPfStep2[language]);

    useEffect(() => {
        //set data for step 2 (it depends by type: PF or PJ)
        if (selectedDataStep1) {
            if (selectedDataStep1.type === 'PF') {
                setDataStep2(DataPfStep2[language]);
            } else {
                setDataStep2(DataPjStep2[language]);
            }
        }
        //
        if (valueFields.length === 0) {
            for (const iterator of dataStep2) {
                setValueFields(prevArr => [...prevArr, null]);
            }
        }
    }, [language, selectedDataStep1])

    function inputFill(index, obj) {
        let newValueFields = valueFields;
        let inputValue = obj.target.value;
        newValueFields[index] = inputValue.length > 0 ? inputValue : null;
        setValueFields(newValueFields)
       
            let dataToSend = {};
            let counter = 0;
            for (const iterator of dataStep2) {
                let key = iterator.name;
                dataToSend[key] = valueFields[counter]
                counter++;
            }

            dispatch(setItem(
                {
                    step: 'step2', data: dataToSend
                }));
        
    }
    return (
        <form className='main-container-step2'>
            {
                dataStep2.map((item, index) => {
                    return (

                        <label key={item.label}>
                            <input type="text" placeholder={item.label} className='element-input' onChange={inputFill.bind(this, index)} />
                        </label>
                    )
                })
            }
        </form>
    )
}
export default Step2Desktop;