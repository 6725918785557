import './Home.scss';
import { useSelector } from 'react-redux';

import HomeMobile from './mobile/Home.mobile';
import HomeTablet from './tablet/Home.tablet';
import HomeDesktop from './desktop/Home.desktop';
import HomeMobileW360 from './mobile/Home.mobile _w_max_360';

function Home() {
  const device = useSelector((state) => state.deviceType.device);

  return (
    <div>
      {
      device === 'mobile_w_max_360'? <HomeMobileW360/> : 
      device === 'mobile'? <HomeMobile/> : 
      device === 'tablet' ? <HomeTablet/> : 
      device === 'desktop' ? <HomeDesktop/> : 
      null
    } 
    </div>
  )
}

export default Home;
