import LanguageSwitcherData from './LanguageSwitcherData';

const BLOG_DATA = (()=>{

    const TITLE_PAGE = {
        RO:{
            title:'Blog'
        },

        EN:{
            title:'Blog'
        }
    }

    const CONTENT = {
        RO:[
            {
                index:-1,
                title:"Casele sustenabile si viitorul arhitecturii moderne",
                subtitle: "Rezidential",
                location:'Bucuresti',
                shortDescription: "Apartamentul din situatia existenta avea o structura invechita, dar printr-o recompartimentare optima si eficienta am obtinut un spatiu deschis, modern si pozitiv. Stilul designului interior este modern, marmura. [...]",
                description: "Apartamentul din situatia existenta avea o structura invechita, dar printr-o recompartimentare optima si eficienta am obtinut un spatiu deschis, modern si pozitiv. Stilul designului interior este modern, marmura, lemnul si materialele textile sunt folosite pentru a da caldura atmosferei.",
                pathImageDescription:'blog/casele_sustenabile_si_viitorul_arhitecturii_moderne/cover-article.png',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 10
            },

            {
                index:-1,
                title:"Rafinamentul caselor de odinioara",
                subtitle: "Rezidential",
                location:'Brasov',
                description: "Locuinta se afla in Brasov, in zona montana. Casa este de P+M, s-a dorit integrarea acesteia in peisajul montan prin folosirea lemnului, a pietrei si prin propunerea acoperisului in 2 ape carcateristice zonei. Casa este partial invelita in lemn de cedru, deschisa spre exterior prin numeroase suprafete vitrate largi,si catre terasa accesibila din living prin usi glisante. Compartimentarea propusa este una simpla, minmala, dar functionala si ergonomica. Parterul are urmatoarele functiuni: hol acces, bucatarie open-space cu dining, living, depozitare, 3 dormitoare cu baie proprie. La nivelul mansardei se afla casa scarii, holul, 3 dormitoare cu o baie proprie si un birou.",
                shortDescription: "Locuinta se afla in Brasov, in zona montana. Casa este de P+M, s-a dorit integrarea acesteia in peisajul montan prin folosirea lemnului, a pietrei si prin propunerea acoperisului in 2 ape carcateristice zonei. [...]",
                pathImageDescription:'blog/rafinamentul_caselor_de_odinioara/cover-article.png',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 7
            },

            {
                index:-1,
                title:"Arta simplitatii",
                subtitle: "Concurs",
                location:'Brasov',
                description: "Conceptul carea a stat la baza realizarii proiectului a fost sa cream o legatura intre interior si exterior, astfel incat gradina sa devina o extensie a spatiului interior. Dat fiind faptul ca caldirea este existenta, cu spatii relativ mici, ne-am propus sa aducem mai multa lumina naturala la interior si sa cream o locuinta moderna caracterizata de un spatiu liber. Propunerea noastra in reabilitarea imobilului a pornit de la pastrarea peretilor exteriori, pastrarea golurilor in planul fatedelor, intervenind la nivelul compartimantarilor interioare asftel incat acestea sa devina spatii libere si luminoase care sa beneficieze de o legatura directa cu zonele exterioare; In zona gradinii se afla amplasata si piscinia care are integrat in acoperis un sistem de lamele retractabile pentru a putea functiona pe perioada verii in sistem deschis. La fel si inchiderile perimetrale ale piscinei le-am propus glisante pe alocuri, astfel incat in scenariul verii sa fie unul in care spatiul piscinei sa cat mai deschis si sa comunice cu gradina. Exteriorul este o combinatie de vechi si nou, in care predomina stuctura metalica a piscinei si local placarile cu piatra naturala. Spatiile teraselor au fost decorate folosindu-se o paleta restransa de materiale si finisaje, fiind definitorie materialitatea care reflecta lumina si creeaza impresia unor spatii mai mari.",
                shortDescription: "Locuinta se afla in Brasov, in zona montana. Casa este de P+M, s-a dorit integrarea acesteia in peisajul montan prin folosirea lemnului, a pietrei si prin propunerea acoperisului in 2 ape carcateristice zonei. [...]",
                pathImageDescription:'blog/arta_simplitatii/cover-article.png',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 6
            
            }
        ],

        EN:[
            {
                index:-1,
                title:"Nerva T",
                subtitle: "Residential",
                location:'Bucharest',
                description: "The apartment in the existing situation had an outdated structure, but through an optimal and efficient repartitioning we obtained an open, modern and positive space. The interior design style is modern, marble, wood and textiles are used to give warmth to the atmospher.",
                shortDescription: "Locuinta se afla in Brasov, in zona montana. Casa este de P+M, s-a dorit integrarea acesteia in peisajul montan prin folosirea lemnului, a pietrei si prin propunerea acoperisului in 2 ape carcateristice zonei. [...]",
                pathImageDescription:'blog/casele_sustenabile_si_viitorul_arhitecturii_moderne/cover-article.png',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 10
            },

            {
                index:-1,
                title:"Forest House B",
                subtitle: "Residential",
                location:'Brasov',
                description: "The house is located in Brasov, in the mountain area. The house has a ground floor and an attic. We  integrate it into the mountain landscape by using wood, stone and by proposing the roof  characteristic of the area. The house is open to the outside through numerous wide glazed surfaces, and to the terrace accessible from the living room through sliding doors. The ground floor has the following functions: entrance hall, open-space kitchen with dining room, living room, storage, 3 bedrooms with private bathroom. At the attic level there is the staircase, the hall, 3 bedrooms with their own bathroom and an office.",
                shortDescription: "Locuinta se afla in Brasov, in zona montana. Casa este de P+M, s-a dorit integrarea acesteia in peisajul montan prin folosirea lemnului, a pietrei si prin propunerea acoperisului in 2 ape carcateristice zonei. [...]",
                pathImageDescription:'blog/casele_sustenabile_si_viitorul_arhitecturii_moderne/cover-article.png',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 7
            },

            {
                index:-1,
                title:"Private residence conversion concept Arad",
                subtitle: "Competition",
                location:'Brasov',
                description: "The concept behind the realization of the project was to create a connection between interior and exterior, so that the garden becomes an extension of the interior space. For the existing heating with relatively small spaces, we proposed to bring more natural light to the interior and create a modern home characterized by a free space. Our proposal for the rehabilitation of the building started from the preservation of the exterior walls, intervening at the level of the interior compartments so that they become free, bright spaces that benefit a connection with the exterior areas. In the garden area, there is also the swimming pool, which has a system of retractable slats integrated into the roof to be able to operate in an open system during the summer. As well as the perimeter closures of the swimming pool, we have proposed sliding system, so that in the summer scenario, the pool space is as open as possible and communicates with the garden. The exterior is a combination of old and new, in which the metal structure of the pool predominate. The spaces of the terraces were decorated using a narrow palette of materials and finishes, the materiality that transmits light and creates the impression of larger spaces being defining.",
                shortDescription: "Locuinta se afla in Brasov, in zona montana. Casa este de P+M, s-a dorit integrarea acesteia in peisajul montan prin folosirea lemnului, a pietrei si prin propunerea acoperisului in 2 ape carcateristice zonei. [...]",
                pathImageDescription:'blog/casele_sustenabile_si_viitorul_arhitecturii_moderne/cover-article.png',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 6
            
            },
            
            {
                index:-1,
                title:"Rehabilitation and extension of house SZ", 
                subtitle: "Residential",
                location:'Brasov',
                description: " The house is located in Brasov, in the central area of the city, in the architectural reserve of the urban ensemble  \"Bulumana - Cetatii Hill\" . The client wants to make the existing bridge habitable. Thus, due to the conformation of the existing roof, but also to the advanced stage of degradation of the structural elements of the roof,we proposed to modify the conformation of the existing roof by creating a new structure at the roof level. The existing shape will be preserved, only its slopes will be modified. Windows will be installed for efficient lighting of the attic and the existing chimneys will be demolished because they have no functional role. A balcony will be created in the volume of the roof on the South side. At the level of the 1st floor, for an efficient space, it is desired to demolish some existing interior walls. The attic will be a modern space, with natural lighting through Velux type windows. In the interior design of the attic, some pieces of furniture and textiles were proposed as minimalist as possible, in neutral colors, such as: white, beige, gray, nude, black and other similar tones.",
                shortDescription: "Locuinta se afla in Brasov, in zona montana. Casa este de P+M, s-a dorit integrarea acesteia in peisajul montan prin folosirea lemnului, a pietrei si prin propunerea acoperisului in 2 ape carcateristice zonei. [...]",
                pathImageDescription:'blog/casele_sustenabile_si_viitorul_arhitecturii_moderne/cover-article.png',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 5
            },
            
            {
                index:-1,
                title:"FOREST HOUSE  C",
                subtitle: "Residential",
                location:'Brasov',
                description: "The house is located in Brasov, in the mountain area. The house has a ground floor and an attic. We  integrate it into the mountain landscape by using wood, stone and by proposing the roof  characteristic of the area. The house is open to the outside through numerous wide glazed surfaces, and to the terrace accessible from the living room through sliding doors. The ground floor has the following functions: entrance hall, open-space kitchen with dining room, living room, storage, 3 bedrooms with private bathroom. At the attic level there is the staircase, the hall, 3 bedrooms with their own bathroom and an office.",
                shortDescription: "Locuinta se afla in Brasov, in zona montana. Casa este de P+M, s-a dorit integrarea acesteia in peisajul montan prin folosirea lemnului, a pietrei si prin propunerea acoperisului in 2 ape carcateristice zonei. [...]",
                pathImageDescription:'blog/casele_sustenabile_si_viitorul_arhitecturii_moderne/cover-article.png',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 5
            },

            {
                index:-1,
                title:"Event hall A",
                subtitle: "Horeca/ Public catering",
                location:'Brasov',
                description: "Event hall, located in Ialomita. The maximum capacity of the hall was designed for 200 people. The volume of the hall also includes the related facilities (sanitary groups for customers and staff, kitchen, food storage, changing rooms, etc). The space is glazed, to create a visual connection with the orchard where the event hall is located. Adjacent to the hall is a summer terrace, served by the same kitchen. Access to the event hall is achieved through a pergola on the metal structure that marks the entrance and opens to the water area, with a powerful visual and sensory impact on the exterior design.",
                shortDescription: "Locuinta se afla in Brasov, in zona montana. Casa este de P+M, s-a dorit integrarea acesteia in peisajul montan prin folosirea lemnului, a pietrei si prin propunerea acoperisului in 2 ape carcateristice zonei. [...]",
                pathImageDescription:'blog/casele_sustenabile_si_viitorul_arhitecturii_moderne/cover-article.png',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 4
            },
            
            {
                index:-1,
                title:"Union square development concept in Rasnov",
                subtitle: "Competition ",
                location:'Brasov',
                description: "Starting from the idea of the medieval square, we proposed emphasizing it as a wide space in opposition to the narrow streets of the city, which automatically lead all entrance roads to the central square. The project aims to revitalize the Unirii market through the following interventions:\ n - the restoration and  preservation of the buildings that outline the market; \n The presence of the fountain and a church, with the aim of visually reconstructing in the most subtle way, the medieval structure characteristic of medieval squares; \n The demolition of the fence of the Evangelical church that blocks the perspective and its relationship with the market. Also, the old building of the former town hall gained importance in relation to the market; \n We propose the water area with the role of remembering the tradition and ritual of the medieval fountains, which in the past also had a functional role.",
                shortDescription: "Locuinta se afla in Brasov, in zona montana. Casa este de P+M, s-a dorit integrarea acesteia in peisajul montan prin folosirea lemnului, a pietrei si prin propunerea acoperisului in 2 ape carcateristice zonei. [...]",
                pathImageDescription:'blog/casele_sustenabile_si_viitorul_arhitecturii_moderne/cover-article.png',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 4
            
            }
        ]
    }

     
    function getContent(){
        let currentLanguage = LanguageSwitcherData.getCurrentLanguage();
        return CONTENT[currentLanguage];
    }

    function getArticle(indexProject){
        let currentLanguage = LanguageSwitcherData.getCurrentLanguage();
        return CONTENT[currentLanguage][indexProject];
    }

    function getTitlePage(){
        let currentLanguage = LanguageSwitcherData.getCurrentLanguage();
        return TITLE_PAGE[currentLanguage]['title'];
    }

    return {
        getContent:getContent,
        getArticle:getArticle,
        getTitlePage:getTitlePage,
    }
})();

export default BLOG_DATA;