const HOME_DATA = (()=>{
    const HOME = {
        mobile_w_max_360:{
            RO:'Misiunea MDRarchitecture este de a proiecta pentru un impact de durată și de a contribui la provocările urbanizării. \n Suntem o echipă experimentată, tânără și energică de profesioniști în arhitectura. Oferim servicii de arhitectura, design arhitectural, relevee constructii si vizualizari 3D.',
            EN:'MDR architecture\'s mission is to design for lasting impact and contribute to the challenges of urbanization. \n We are an experienced, young and energetic team of architecture professionals who offer architecture services, architectural design, construction surveys and 3D visualizations.'
        },
        mobile:{
            RO:'Misiunea MDRarchitecture este de a proiecta pentru un impact de durată și de a contribui la provocările urbanizării. \n Suntem o echipă experimentată, tânără și energică de profesioniști în arhitectura. Oferim servicii de arhitectura, design arhitectural, relevee constructii si vizualizari 3D.',
            EN:'MDR architecture\'s mission is to design for lasting impact and contribute to the challenges of urbanization. \n We are an experienced, young and energetic team of architecture professionals who offer architecture services, architectural design, construction surveys and 3D visualizations.'
        },
        tablet:{
            RO:'Misiunea MDRarchitecture este de a proiecta pentru un impact de durată și de a contribui la provocările urbanizării. \n Suntem o echipă experimentată, tânără și energică de profesioniști în arhitectura care oferă servicii de arhitectura, design arhitectural, relevee constructii si vizualizari 3D. Oferim în mod constant clienților noștri o atenție și servicii orientate către client prin practica noastră responsabilă de arhitectură. \n Vă așteptăm să explorați site-ul nostru web sau să contactați biroul nostru la 0787540101 pentru a lua legatura cu noi.',
            EN:'MDR architecture\'s mission is to design for lasting impact and contribute to the challenges of urbanization. \n We are an experienced, young and energetic team of architecture professionals who offer architecture services, architectural design, construction surveys and 3D visualizations. We consistently provide our clients with customer-oriented attention and service through our responsible architecture practice. \n We welcome you to explore our website or contact our office on +40787540101 to get in touch with us.'
        },
        desktop:{
            RO:'Misiunea MDRarchitecture este de a proiecta pentru un impact de durată și de a contribui la provocările urbanizării. \n Suntem o echipă experimentată, tânără și energică de profesioniști în arhitectura care oferă servicii de arhitectura, design arhitectural, relevee constructii si vizualizari 3D. Oferim în mod constant clienților noștri o atenție și servicii orientate către client prin practica noastră responsabilă de arhitectură. \n Vă așteptăm să explorați site-ul nostru web sau să contactați biroul nostru la 0787540101 pentru a lua legatura cu noi.',
            EN:'MDR architecture\'s mission is to design for lasting impact and contribute to the challenges of urbanization. \n We are an experienced, young and energetic team of architecture professionals who offer architecture services, architectural design, construction surveys and 3D visualizations. We consistently provide our clients with customer-oriented attention and service through our responsible architecture practice. \n We welcome you to explore our website or contact our office on +40787540101 to get in touch with us.'
        }
        
    }

    function getHome(currentDevice, language){
        if(HOME[currentDevice]){
            return HOME[currentDevice][language];
        }
       
    }

    return {
        getHome:getHome
    }
})();

export default HOME_DATA;