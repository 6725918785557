import './Proiecte.desktop.scss';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Projects } from '../../../../data/ProjectsData';
import ProgressiveImage from '../../../../components/progressiveImage/ProgressiveImage';
import ImageWitchAnimationType1 from '../../../../components/selectImageAnimationType1/SelectImageAnimationType1';

function ProjectsDesktop() {
  const language = useSelector((state) => state.language.language);
  const projects = Projects[language];

  let [descriptionBox, setDescriptionBox] = useState([]);
  let [imageBox, setImageBox] = useState([]);
  useEffect(() => {
    if (descriptionBox.length === 0) {
      projects.map((projects, index) => {
        setDescriptionBox(prevArr => [...prevArr, `thumb-project-description thumb-project-description-${index}`]);
        setImageBox(prevArr => [...prevArr, `thumb-project-image thumb-project-image-${index}`]);
      })
    }
  }, [language])

  return (

    <div className='container-project-page'>
      {projects.map((projects, index) => {
        let [title, subtitle, description, location, imagesThumbs] = [null, null, null, null, null];
        let [leftContent, rightContent, leftBox, rightBox] = [null, null]
        let [contentLeft, contentRight] = [null, null];
        let [data1, data2] = [null, null];
        title = projects.title;
        subtitle = projects.subtitle;
        description = projects.description;
        location = projects.location;
        imagesThumbs =
          <ProgressiveImage
            src={require('../../../assets/' + projects.fileName + '/thumb.jpg')}
            placeholderSrc={require('../../../assets/' + projects.fileName + '/thumb_tiny.jpg')}
            className='project-image'
          />;
        if (index % 2 != 0) {
          leftBox = descriptionBox[index];
          rightBox = imageBox[index];
          //-----
          leftContent = <span>
            <div className='item-bg'>
              <div className='title-project-from-projects-page'>{title}</div>
              <div className='subtitle-project-from-projects-page'>{subtitle}</div>
              <div className='location-project-from-projects-page'>{location}</div>
            </div>
          </span>
          rightContent = <span>{imagesThumbs}</span>
          //----------
          data1 = {
            targetProject: 'project_' + index,
            content: leftContent
          }
          data2 = {
            targetProject: 'project_' + index,
            content: rightContent
          }
        } else {
          leftBox = imageBox[index];
          rightBox = descriptionBox[index];
          //---------
          leftContent = <span>{imagesThumbs}</span>
          rightContent = <span>
            <div className='item-bg'>
              <div className='title-project-from-projects-page'>{title}</div>
              <div className='subtitle-project-from-projects-page'>{subtitle}</div>
              <div className='location-project-from-projects-page'>{location}</div>
            </div>
          </span>
          //------------
          data1 = {
            targetProject: 'project_' + index,
            content: leftContent
          }
          data2 = {
            targetProject: 'project_' + index,
            content: rightContent
          }
        }
        return (
          <div className='box-project' key={(index + Math.random()).toString()}>
            <div className='row-project'>
              <span className={leftBox}>
                <ImageWitchAnimationType1 data={data1} />
              </span>
              <span className={rightBox}>
                <ImageWitchAnimationType1 data={data2} />
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ProjectsDesktop;
