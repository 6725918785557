import { useEffect, useState } from "react";
import './messageSentMail.scss';

const ConsoleMessageSentMail = ({warningText}) => {

    const [mainContainerConsole, setMainContainerConsole] = useState('main-container-console-send-mail-visible');
    const [classConsole, setClassConsole] = useState('console-message-visible');
    const [classBgConsole, setClassBgConsole] = useState('bg-console-message-visible');
/* Va multumim! <br/>Emailul a fost trimis! <br/>Vom analiza cererea dvs. si va vom contacta in cel mai scurt timp! */
    useEffect(() => {
        setTimeout(function () {
           
            setClassConsole('console-message-hide');
            setClassBgConsole('bg-console-message-hide');
            setMainContainerConsole('main-container-console-send-mail-hide')

        }, 2000);
    }, [])
    
    return(
        <div className={mainContainerConsole}>
            <div className={classBgConsole}></div>
            <div className={classConsole}> {warningText}
               </div>
        </div>
    )
}

export default ConsoleMessageSentMail;