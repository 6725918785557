import './step1.desktop.scss';
import { useEffect, useState } from 'react';
import { setItem } from '../../../../../store/actions/configuratorFormChecksActions';
import { useSelector, useDispatch } from 'react-redux';
import { TitleStep1 } from '../../../../../data/configurator/step1/dataStep1';
import { DataStep1 } from '../../../../../data/configurator/step1/dataStep1';
import Thumb from '../../../../../components/thumb/Thumb';

function Step1Desktop() {
    const dispatch = useDispatch();
    const selectedDataStep1 = useSelector((state)=>state.configuratorCheckbox.checkboxList.step1);
    const language = useSelector((state)=>state.language.language);
    let [selectedItem, setSelectedItem] = useState([]);
    let [indexSelectedItem, setIndexSelectedItem] = useState(-1);
    let [titleStep1, setTitleStep1] = useState(TitleStep1[language]);
    let [dataStep1, setDataStep1] = useState(DataStep1[language]);

    useEffect(() => {
        if (selectedItem.length === 0) {
            for (const index in dataStep1) {
                setSelectedItem(prevArr => [...prevArr, `container-step-1-item-${Number(index)} deselected`]);
            }
        }

        setTitleStep1(TitleStep1[language]);
        setDataStep1(DataStep1[language]);
        //
    }, [indexSelectedItem, language]);

    function selectBuild(item, index) {
       
        //reset array items, set as `deselected` all elements
        let newSelectedItem = selectedItem;
        for (const index in dataStep1) {
            newSelectedItem[index] = `container-step-1-item-${Number(index)} deselected`;
        }
        setSelectedItem(newSelectedItem);
        //set new item as `selected`
        newSelectedItem = selectedItem;
        newSelectedItem[index] = `container-step-1-item-${Number(index)} selected`;
        setSelectedItem(newSelectedItem);

        setIndexSelectedItem(index);
        //
        dispatch(setItem({ step: 'step1', data: dataStep1[index] }))
    }
    return (
        <div className='main-step-1'>
            <div className='content-category-step_1'>


                {dataStep1.map((item, index) => {
                    return (
                        <div key={Math.random()} className='content-child-subcategory-constructions'>
                            <Thumb
                            idClass={selectedItem[index]}
                            label={item.label}
                            callback={selectBuild.bind(this, item, index)}
                        />
                        </div>
                    )
                })}

            </div>
        </div>
    )
}
export default Step1Desktop;