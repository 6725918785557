import './Proiecte.scss';
import { useSelector } from 'react-redux';

import ProjectsMobile from './mobile/Proiecte.mobile';
import ProjectsTablet from './tablet/Proiecte.tablet';
import ProjectsDesktop from './desktop/Proiecte.desktop';

function Projects() {
  const device = useSelector((state) => state.deviceType.device);

  return (
    <div>
      {
      device === 'mobile' || device === 'mobile_w_max_360'? <ProjectsMobile/> : 
      device === 'tablet' ? <ProjectsTablet/> : 
      device === 'desktop' ? <ProjectsDesktop/> : 
      null
    } 
    </div>
  )
}

export default Projects;
