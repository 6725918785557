
import './PagesHieracky.desktop.scss';
import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import MenuData from '../../data/MenuData';
import { useSelector, useDispatch } from 'react-redux';
import { setFirstTitle } from '../../store/actions/hierackyActions';
import { setSecondTitle } from '../../store/actions/hierackyActions';
import { setThirdTitle } from '../../store/actions/hierackyActions';
import { Projects } from '../../data/ProjectsData';

const PagesHierackyDesktop = () => {
  const dispatch = useDispatch();
  const language = useSelector((state)=>state.language.language);
  const [dataMenu, setDataMenu] = useState(MenuData.getDataMenu(language));

  const firstPageTitle = useSelector((state) => state.hierackyReducer.firstTitle);
  const secondPageTitle = useSelector(state => state.hierackyReducer.secondTitle);
  const thirdPageTitle = useSelector((state) => state.hierackyReducer.thirdTitle);
  const indexProject = useSelector((state) => state.hierackyReducer.indexProject);





  let [routeFirstPage, setRouteFirstPage] = useState(getRouteID(firstPageTitle));
  let [routeSecondPage, setRouteSecondPage] = useState(getRouteID(secondPageTitle));
  let [routeThirdPage, setRouteThirdPage] = useState(getRouteID(thirdPageTitle));
  
  let [secondPage, setSecondPage] = useState('');
  let [firstPageClassName, setFirstPageClassName] = useState('first-page');

  let [secondPageClassName, setSecondPageClassName] = useState('second-page');
  let [thirdPageClassName, setThirdPageClassName] = useState('third-page');

  let [firstSimbol, setClassNameFirstSimbol] = useState('first-simbol-between-pages');
  let [secondSimbol, setClassNameSecondSimbol] = useState('second-simbol-between-pages');
  
  const [hieracky, setHierackyVisibility] = useState('hieracky-showed');

  const deviceType = useSelector((state) => state.deviceType.device);


  function showFirstPage() {
    setFirstPageClassName('first-page');
    setClassNameFirstSimbol('first-simbol-between-pages');
  }

  function getRouteID(id){
    let routeId;
    for (const iterator of dataMenu) {
      if(id === iterator.id) routeId = iterator.route;
    }
    return routeId;
  }

  function showSecondPage() {
    
    setSecondPageClassName('second-page');
  }
  
  function showThirdPage() {
    setThirdPageClassName('third-page');
    setClassNameSecondSimbol('second-simbol-between-pages');
   
  }

  function hideFirstPage() {
    setFirstPageClassName('first-page-hide');
  }

  function hideSecondPage() {
    setSecondPageClassName('second-page-hide');
    setClassNameFirstSimbol('first-simbol-between-pages-hide');
  }

  function hideThirdPage() {
    setThirdPageClassName('third-page-hide');
    setClassNameSecondSimbol('second-simbol-between-pages-hide');
  }

  function action(id) {
    switch (id) {
      case 'firstPage':hideFirstPage(); hideSecondPage(); hideThirdPage();break;
      case 'secondPage':hideThirdPage(); break;
    }
  }


  //resize window
  useEffect(() => {
    
    setDataMenu(MenuData.getDataMenu(language));
    let projectData = Projects[language][indexProject];
    let firstPageTitleUpdated = MenuData.getIdOnChangeLanguage(firstPageTitle, language);
    let secondPageTitleUpdated = MenuData.getIdOnChangeLanguage(secondPageTitle, language);
    let thirdPageTitleUpdated = (projectData) ? projectData.title: null;
    dispatch(setFirstTitle(firstPageTitleUpdated));
    dispatch(setSecondTitle(secondPageTitleUpdated));
    dispatch(setThirdTitle(thirdPageTitleUpdated));
   
    if(deviceType === 'mobile'){
      hideFirstPage();
      hideSecondPage();
      hideThirdPage();
    }else{
      if(!firstPageTitle){
        hideFirstPage();
        hideSecondPage();
        hideThirdPage();
      }else{
        showFirstPage();
        showSecondPage();
      }

      if(!secondPageTitle){
        hideThirdPage();
      }

      if(thirdPageTitle){
        showThirdPage();
      }else{
        hideThirdPage();
      }
    }
    setRouteFirstPage(getRouteID(firstPageTitle))
    setRouteSecondPage(getRouteID(secondPageTitle))
    
    
  }, [dataMenu, firstPageTitle, secondPageTitle, thirdPageTitle, language])




  return (
  
    <div className={hieracky}>
      <span className={firstPageClassName}>
      <Link className={firstPageClassName} style={{textDecoration: 'none'}} to={"/"+routeFirstPage} onClick = {action.bind(this, 'firstPage')}>
          <span >{firstPageTitle}</span>
      </Link>
      </span>
      <span className={firstSimbol}>{">"}</span>
      <span className={secondPageClassName}>
      <Link className={secondPageClassName} style={{textDecoration: 'none'}} to={"/"+routeSecondPage} onClick = {action.bind(this, 'secondPage')}>
          <span >{secondPageTitle}</span>
      </Link>
        </span>
      <span className={secondSimbol}>{">"}
      
      </span>
      <span className={thirdPageClassName}>
          <span >{thirdPageTitle}</span>
    
      </span>
    </div>
  );
}

export {PagesHierackyDesktop};
