import './step4.tablet.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setItem } from '../../../../../store/actions/configuratorFormChecksActions';
import { TitleStep4 } from '../../../../../data/configurator/step1/dataStep4';
import { DataStep4 } from '../../../../../data/configurator/step1/dataStep4';

function Step4Tablet() {
    const dispatch = useDispatch();
    let configuratorCheckBox = useSelector((state) => state.configuratorCheckbox.checkboxList);
    const language = useSelector((state)=>state.language.language);
    let [selectedItem, setSelectedItem] = useState([]);
    let [indexSelectedItem, setIndexSelectedItem] = useState(-1);
    let [titleStep4, setTitleStep4] = useState(TitleStep4[language]);
    let [dataStep4, setDataStep4] = useState(DataStep4[language]);
   
    useEffect(() => {
      
        if (selectedItem.length === 0) {
            for (const index in dataStep4) {
                setSelectedItem(prevArr => [...prevArr, `container-step-4-item-${Number(index)} deselected`]);
            }
        }
    }, [indexSelectedItem])

    function selectBuild(item, index) {
        //reset array items, set as `deselected` all elements
       
        let idSelected = `container-step-4-item-${Number(index)} selected`;
        let idDeselected = `container-step-4-item-${Number(index)} deselected`;
        //set new item as `selected`
        let newSelectedItem = selectedItem;
        newSelectedItem[index] = (selectedItem[index] == idSelected)?idDeselected:idSelected;
        setSelectedItem(newSelectedItem);
        setIndexSelectedItem(index);
        //
        let updatedDataStep4 = [];
        for (const index in selectedItem) {
            if(selectedItem[index] === 'container-step-4-item-'+index+' selected'){
                updatedDataStep4.push(dataStep4[index]);
            } 
                
        }
        dispatch(setItem({ step: 'step4', data: updatedDataStep4 }))
    }

    return (
        <div className='main-step-4-tablet'>
           
<div className='content-step-4-tablet'>
    
                {dataStep4.map((item, index) => {
                    return (
                        <div key={Math.random()} className='row-main-step-4-tablet'>
                         
                            <div className={selectedItem[index]} onClick={selectBuild.bind(this, item, index)}>

                                {item.label}
                            </div>
                            
                        </div>
                    )
                })}
                </div>
                <div className='detail-ids-tablet'>
                    {dataStep4.map((item, index) => {
                        return (
                            <div key={Math.random()} className='ids-detailed-tablet'>
                                <span className='id-item-detail-tablet'>
                                    *{item.name}
                                </span>
                                <span className='item-detail-tablet'>
                                    {item.detail}
                                </span>
                                
                            </div>
                        )
                    })}
                </div>
            </div>
            

    )
}
export default Step4Tablet;

