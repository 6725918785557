
import './Menu.mobile.scss';
import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import MenuData from '../../data/MenuData';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setFirstTitle } from '../../store/actions/hierackyActions';
import { setSecondTitle } from '../../store/actions/hierackyActions';
import { setThirdTitle } from '../../store/actions/hierackyActions';
import { setIndexProject } from '../../store/actions/hierackyActions';

function MenuMobile() {
  const dispatch = useDispatch();
  //resize window
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  const firstTitlePage = useSelector((state) => state.hierackyReducer.firstTitle);
  const language = useSelector((state) => state.language.language);
  const deviceType = useSelector(state => state.deviceType.device);

  //menu
  //const [pagesHieracky, setPagesHieracky] = useState();
  const [burger_class, setBurgerClass] = useState("burger-bar", 'unclicked');
  const [menu_class, setMenuClass] = useState("menu hidden");
  const [menu_items, setIsMenuItems] = useState("menu-items hidden-items");
  const [container_items, setIsMenuItemsParent] = useState("container-items hidden-items-parent");
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [dataMenu, setDataMenu] = useState(MenuData.getDataMenu(language));
  let [desktopMenuContentDisplay, setDesktopMenuContentDisplay] = useState('minimal');

  //const {valueHieracky, setValueHieracky} = useContext(HierackyContext);

  const [title, setTitle] = useState("");

  const [titlePage, setTitlePage] = useState('hide-title-page');

  //resize window
  useEffect(() => {

    setTitle(MenuData.getUpdatedTitlePage(title, language));

    setDataMenu(MenuData.getDataMenu(language));



     // if (deviceType === 'mobile') {
        //imageRef.current.src = logoMobile;
        if (isMenuClicked === true) {
          setIsMenuItemsParent("container-items visible-items-parent");

        } else {
          setIsMenuItemsParent("container-items hidden-items-parent");
          setBurgerClass("burger-bar unclicked");
          setMenuClass("menu hidden");
          setIsMenuItems("menu-items hidden-items-with-animation")

        }

      //}



  }, [language, isMenuClicked, deviceType, title])


  //menu
  const updateMenu = () => {

    if (!isMenuClicked) {
      setBurgerClass("burger-bar clicked");
      setMenuClass("menu visible");
      setIsMenuItems("menu-items visible-items-with-animation")
      setIsMenuItemsParent("container-items visible-items-parent");
    } else {
      setIsMenuItemsParent("container-items hidden-items-parent");
      setBurgerClass("burger-bar unclicked");
      setMenuClass("menu hidden");
      setIsMenuItems("menu-items hidden-items-with-animation")
    }
    setIsMenuClicked(!isMenuClicked);
  }

  const action = (menuID, e) => {
    setTitle(menuID);

   
      setBurgerClass("burger-bar unclicked");
      //setMenuClass("menu hidden");
     // setIsMenuItems("menu-items hidden-items-with-animation")
      setIsMenuClicked(!isMenuClicked);
    
    scrollToTop();
    //setValueHieracky(menuID);

    if (!menuID || menuID === 'Acasa' || menuID === 'Home') {
      dispatch(setFirstTitle(null));
      dispatch(setSecondTitle(null));
      dispatch(setThirdTitle(null))
      dispatch(setIndexProject(null));
      //setPagesHieracky('null', null);
      setTitlePage('hide-title-page');
    } else {
      dispatch(setFirstTitle('Home'));
      dispatch(setSecondTitle(menuID));
      dispatch(setThirdTitle(null));
      dispatch(setIndexProject(null));
      //setPagesHieracky(idFirstPage, null);
      setTitlePage('show-title-page');
    }
  }
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }
  return (
    <>
      <div className='header'>
        <div id="logo"><span className='mdr-text'>MDR</span><span className='architecture-text'>architecture</span></div>
        <div id="main-menu">
          <nav>
            <div className="burger-menu">
              <div className={burger_class} onClick={updateMenu}></div>
              <div className={burger_class} onClick={updateMenu}></div>
              <div className={burger_class} onClick={updateMenu}></div>
            </div>
          </nav>
        </div>
        <div className={titlePage}><h1 className={titlePage}>{title}</h1></div>

        <div className={container_items}>
          <div className={menu_class}>
            <ul>

              {
                Array.isArray(dataMenu)
                  ?
                  dataMenu.map((data, index) => {
                    let [id, route] = [data.id, data.route];
                    let firstLetterMenu = id.split('')[0];
                    let restOfLettersMenu = '';
                    for (const index in id) {
                      if (index != 0) {
                        restOfLettersMenu += id[index];
                      }
                    }
                    return (
                      <div key={(index + Math.random()).toString()}>
                        <Link className='link-main-menu' style={{ textDecoration: 'none' }} to={"/" + route} onClick={action.bind(this, id)}>
                          {
                            <li className={menu_items}>


                              <span className='menu-li-text-mobile'>

                                {id}
                              </span>

                            </li>
                          }


                        </Link>
                      </div>

                    )
                  })
                  :
                  null
              }
            </ul>
          </div>

        </div>
      </div>
    </>
  );
}

export default MenuMobile;
