const CONTACT_DATA = (()=>{

    const CONTACT_PAGE_TITLE = {
        RO:{
            title:'Contact'
        },

        EN:{
            title:'Contact'
        }
    }

    const CONTACT = {
        RO:
            {
            title_left:"Mesajul tau",
            name_left:"Numele tau",
            email: "Email",
            message_left:"Mesajul tau",
            btn_name:'Trimite',

            title_right:"Informatii",
            adress_title:"Adresa",
            adress_content:"Str. Iuliu Maniu nr.47, Brasov",
            phone_title:"Telefon",
            phone_content:"+4 076 456 0042",
            email_name:'Email',
            email_content:'office@mdr-architecture.ro',
            website_name:'Website',
            website_content:'www.mdr-architecture.ro'
            }
        ,

        EN:
            {
            title_left:"Your message",
            name_left:"Your name",
            email: "Email",
            message_left:"Your massage",
            btn_name:'Send',

            title_right:"INFORMATIONS",
            adress_title:"Adress",
            adress_content:"47 Iuliu Maniu Street, Brasov",
            phone_title:"Phone",
            phone_content:"+4 076 456 0042",
            email_name:'Email',
            email_content:'office@mdr-architecture.ro',
            website_name:'Website',
            website_content:'www.mdr-architecture.ro'
            }
        
    }

    function getContact(language){
        return CONTACT[language];
    }
    

    function getTitlePage(language){
        if(CONTACT_PAGE_TITLE[language]) return CONTACT_PAGE_TITLE[language]['title'];
    }

    return {
        getContact:getContact,
        getTitlePage:getTitlePage
    }
})();

export default CONTACT_DATA;