

    export const TitleProject = {
        RO:{
            title:'Proiecte'
        },

        EN:{
            title:'Projects'
        }
    }

    export const Projects = {
        RO:[
            {
                index:-1,
                fileName:"casa_cuib",
                title:"Oaza de Relaxare - Casa Cuib",
                subtitle: "Concurs",
                location:'Delta Dunarii',
                description: "Acest concept este mai mult decât o locuință; este un sanctuar de relaxare și regenerare. Este o invitație la a scăpa de agitația cotidiană și a descoperi liniștea și echilibrul în mijlocul naturii, într-un cămin creat cu grijă și dedicat relaxării profunde. Lemnul, cu textura și căldura sa, aduce în interior o parte din esența pădurii, oferindu-ne un refugiu care pare să respire, să trăiască, să comunice cu natura. În acest cadru, panourile de lemn riflate devin povestea de transformare a casei într-un organism viu. Aceste panouri nu sunt doar elemente de design; ele sunt părți dintr-un dialog evolutiv cu mediul. Prin capacitatea lor de a se adapta la schimbările de lumină, la anotimpuri și la starea vremii, ele aduc în spațiu vibrația naturii și schimbarea continuă pe care o aduce aceasta.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 18
            },
            {
                index:-1,
                fileName:"cabinet_psihologie",
                title:"Cabinet Psihologie",
                subtitle: "Centru medical",
                location:'Brasov',
                description: "Ne-am propus sa aducem un plus de valoare spatiilor printr-un design simplist, dar care sa reflecte confortul si increderea in randul pacientilor. Tematica naturii si culorile in tonuri pale folosite pentru a inviora spatiul. Laitmotivul spatiului receptiei - culoarea verde aplicata ca accent intr-un spatiu cu un design modern-minimalist. Receptia este amenajata in stil modern, ca forma - avand linii drepte si realizata din MDF alb mat si cu furnir de lemn natur. Tot in zona receptiei exista si o zona de asteptare cu scaune cu tapiterie colorata. Fiecare cabinet in parte este marcat cu cifra la intrare pentru a imparti specialitatile medicale.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 18
            },
            {
                index:-1,
                title:"Casa Forest B",
                fileName:"casa_forest_b",
                subtitle: "Rezidential",
                location:'Brasov',
                description: "Locuinta se afla in Brasov, in zona montana. Casa este de P+M, s-a dorit integrarea acesteia in peisajul montan prin folosirea lemnului, a pietrei si prin propunerea acoperisului in 2 ape carcateristice zonei. Casa este partial invelita in lemn de cedru, deschisa spre exterior prin numeroase suprafete vitrate largi,si catre terasa accesibila din living prin usi glisante. Compartimentarea propusa este una simpla, minmala, dar functionala si ergonomica. Parterul are urmatoarele functiuni: hol acces, bucatarie open-space cu dining, living, depozitare, 3 dormitoare cu baie proprie. La nivelul mansardei se afla casa scarii, holul, 3 dormitoare cu o baie proprie si un birou.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 7
            },

            {
                index:-1,
                title:"Concept Conversie Resedinta Privata Arad",
                fileName:"concept_conversie_resedinta_privata_arad",
                subtitle: "Concurs",
                location:'Brasov',
                description: "Conceptul carea a stat la baza realizarii proiectului a fost sa cream o legatura intre interior si exterior, astfel incat gradina sa devina o extensie a spatiului interior. Dat fiind faptul ca caldirea este existenta, cu spatii relativ mici, ne-am propus sa aducem mai multa lumina naturala la interior si sa cream o locuinta moderna caracterizata de un spatiu liber. Propunerea noastra in reabilitarea imobilului a pornit de la pastrarea peretilor exteriori, pastrarea golurilor in planul fatedelor, intervenind la nivelul compartimantarilor interioare asftel incat acestea sa devina spatii libere si luminoase care sa beneficieze de o legatura directa cu zonele exterioare; In zona gradinii se afla amplasata si piscinia care are integrat in acoperis un sistem de lamele retractabile pentru a putea functiona pe perioada verii in sistem deschis. La fel si inchiderile perimetrale ale piscinei le-am propus glisante pe alocuri, astfel incat in scenariul verii sa fie unul in care spatiul piscinei sa cat mai deschis si sa comunice cu gradina. Exteriorul este o combinatie de vechi si nou, in care predomina stuctura metalica a piscinei si local placarile cu piatra naturala. Spatiile teraselor au fost decorate folosindu-se o paleta restransa de materiale si finisaje, fiind definitorie materialitatea care reflecta lumina si creeaza impresia unor spatii mai mari.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 6
            
            },
            
            {
                index:-1,
                title:"Reabilitare si extindere Casa SZ",
                fileName:"reabilitare_si_extindere_casa_sz",
                subtitle: "Rezidential",
                location:'Brasov',
                description: " \"Blumana - Dealul Cetatii\". Benficiarul doreste mansardarea podului nelocuibil existent. Astfel, datorita conformatiei acoperisului existent, dar si a gradului avansat de degradare a elementelor structurale ale acoperisului, se propune modificarea conformatiei acoperisului existent prin realizarea unei noi structuri la nivelul acoperisului. In mare masura se va pastra forma existenta a acestuia, se vor modifica doar pantele acestuia. Se vor monta ferestre pentru iluminarea eficienta a mansardei si se vor demoala cosurile de fum existente deoarece acestea nu au nici un rol functional. Prin compartimentarea mansardei se va realiza si un balcon decupat in volumetria acoperisului pe latura de Sud . La niveulul etajului 1, pentru compartimentarea eficienta a spatiului se doreste demolarea unor pereti interiori existenti. Mansarda va fi un spatiu modern, iluminat natural prin intermediul unor ferestre tip velux. In designul interior al mansardei s-au propus câteva piese de mobilier și textile cât mai minimaliste, în culori neutre, precum: alb, bej, gri, nude, negru și alte tonuri asemănătoare. Description  project 3",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 5
            },
            
            {
                index:-1,
                title:"Casa Forest C",
                fileName:"casa_forest_c",
                subtitle: "Rezidential",
                location:'Brasov',
                description: "Locuinta se afla in Brasov, in zona montana. Casa este de P+M, s-a dorit integrarea acesteia in peisajul montan prin folosirea lemnului, a pietrei si prin propunerea acoperisului in 2 ape carcateristice zonei. Casa este partial invelita in lemn de cedru, deschisa spre exterior prin numeroase suprafete vitrate largi,si catre terasa accesibila din living prin usi glisante. Compartimentarea propusa este una simpla, minmala, dar functionala si ergonomica. Parterul are urmatoarele functiuni: hol acces, bucatarie, camera tehnica, living si dining, baie, hol, depozitare si casa scarii. La nivelul mansardei se afla casa scarii, holul, 2 dormitoare cu o baie comuna si dormitorul matrimonial cu baie proprie.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 5
            },
            
            {
                index:-1,
                title:"Sala evenimente A",
                fileName:"sala_evenimente_a",
                subtitle: "Horeca/ Alimentatie publica",
                location:'Brasov',
                description: "Sala de evenimente, situata in Ialomita. . Capacitatea maxima a salii a fost dimensionata astfel incat sa nu fie depasita valoarea de 200 persoane. Volumul salii are inclus si dotarile conexe (grupuri sanitare pentru clienti si pentru personal, bucatarie, depozit alimente, vestiare, etc.) si  este vitrat, pentru a realiza legatura vizuala cu livada in care este dispus salonul fe evenimente. Adiacent salii se afla o terasa de vara, deservita de aceeasi bucatarie. Accesul in salonul de evenimente se realizeaza printr-o pergola pe stuctura metalica care marcheaza intrarea si se deschide catre zona de apa, cu un puterinic impact vizaul si senzorial amenajarii exterioare.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 4
            },
            
            {
                index:-1,
                title:"Concept amenajare Piata Unirii din Rasnov",
                fileName: "concept_amenajare_piata_unirii_din_rasnov",
                subtitle: "Concurs",
                location:'Brasov',
                description: "Pornind de la ideea pietei medievale am propus accentuarea acesteia ca spatiu larg in opozitie strazile inguste ale orasului, care in mod automat toate drumurile de intrare duc in piata centrala. Proiectul isi propune revitalizarea pietei Unirii prin urmatoarele interventii: \n Restaurarea si conservarea cladiriilor care contureaza piata; \n Prezenta fantanii si a unei biserici, cu scopul de a reconstitui vizual intr-un mod cat mai subtil, structura medievala caracteristica pietelor medievale; \n Demolarea imprejmuirii bisericii Evanghelice care blocheaza perspectiva si relatia acesteia cu piata. De asemenea, si cladirea veche a fostei primarii capata importanta in relatia cu piata.\n Propunem zona de apa cu rolul de a aminti traditia si ritualul fantanilor medievale care in trecut acestea aveau si un rol functional.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 4
            
            }
        ],

        EN:[
            {
                index:-1,
                fileName:"casa_cuib",
                title:"Oasis of Relaxation - Casa Cuib",
                subtitle: "Concurs",
                location:'Delta Dunarii',
                description: "This concept is more than a home; it is a sanctuary of relaxation and regeneration. It is an invitation to escape from the daily hustle and bustle and discover peace and balance in the middle of nature, in a home created with care and dedicated to deep relaxation. Wood, with its texture and warmth, brings part of the essence of the forest inside, offering us a refuge that seems to breathe, to live, to communicate with nature. In this setting, the corrugated wooden panels become the story of the transformation of the house into a living organism. These panels are not just design elements; they are part of an evolutionary dialogue with the environment. Through their ability to adapt to changes in light, seasons and weather, they bring the vibrancy of nature into the space and the continuous change it brings..",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 18
            },
            {   index:-1,
                fileName:"cabinet_psihologie",
                title:"Cabinet Psihologie",
                subtitle: "Medical Center",
                location:'Brasov',
                description: "We set out to add value to the spaces through a simplistic design, but which reflects the comfort and confidence of the patients. The theme of nature and the colors in pale tones used to enliven the space. The leitmotif of the reception area - the green color applied as an accent in a space with a modern-minimalist design. The reception is set up in a modern style, in shape - with straight lines and made of matte white MDF with natural wood veneer. Also in the reception area there is a waiting area with chairs with colorful upholstery. Each individual office is marked with a number at the entrance to divide the medical specialties.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 18
            },
            {
                index:-1,
                title:"Nerva T",
                fileName:"nerva_t",
                subtitle: "Residential",
                location:'Bucharest',
                description: "The apartment in the existing situation had an outdated structure, but through an optimal and efficient repartitioning we obtained an open, modern and positive space. The interior design style is modern, marble, wood and textiles are used to give warmth to the atmospher.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 10
            },

            {
                index:-1,
                title:"Forest House B",
                fileName:"casa_forest_b",
                subtitle: "Residential",
                location:'Brasov',
                description: "The house is located in Brasov, in the mountain area. The house has a ground floor and an attic. We  integrate it into the mountain landscape by using wood, stone and by proposing the roof  characteristic of the area. The house is open to the outside through numerous wide glazed surfaces, and to the terrace accessible from the living room through sliding doors. The ground floor has the following functions: entrance hall, open-space kitchen with dining room, living room, storage, 3 bedrooms with private bathroom. At the attic level there is the staircase, the hall, 3 bedrooms with their own bathroom and an office.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 7
            },

            {
                index:-1,
                title:"Private residence conversion concept Arad",
                fileName:"concept_conversie_resedinta_privata_arad",
                subtitle: "Competition",
                location:'Brasov',
                description: "The concept behind the realization of the project was to create a connection between interior and exterior, so that the garden becomes an extension of the interior space. For the existing heating with relatively small spaces, we proposed to bring more natural light to the interior and create a modern home characterized by a free space. Our proposal for the rehabilitation of the building started from the preservation of the exterior walls, intervening at the level of the interior compartments so that they become free, bright spaces that benefit a connection with the exterior areas. In the garden area, there is also the swimming pool, which has a system of retractable slats integrated into the roof to be able to operate in an open system during the summer. As well as the perimeter closures of the swimming pool, we have proposed sliding system, so that in the summer scenario, the pool space is as open as possible and communicates with the garden. The exterior is a combination of old and new, in which the metal structure of the pool predominate. The spaces of the terraces were decorated using a narrow palette of materials and finishes, the materiality that transmits light and creates the impression of larger spaces being defining.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 6
            
            },
            
            {
                index:-1,
                title:"Rehabilitation and extension of house SZ", 
                fileName:"reabilitare_si_extindere_casa_sz",
                subtitle: "Residential",
                location:'Brasov',
                description: " The house is located in Brasov, in the central area of the city, in the architectural reserve of the urban ensemble  \"Bulumana - Cetatii Hill\" . The client wants to make the existing bridge habitable. Thus, due to the conformation of the existing roof, but also to the advanced stage of degradation of the structural elements of the roof,we proposed to modify the conformation of the existing roof by creating a new structure at the roof level. The existing shape will be preserved, only its slopes will be modified. Windows will be installed for efficient lighting of the attic and the existing chimneys will be demolished because they have no functional role. A balcony will be created in the volume of the roof on the South side. At the level of the 1st floor, for an efficient space, it is desired to demolish some existing interior walls. The attic will be a modern space, with natural lighting through Velux type windows. In the interior design of the attic, some pieces of furniture and textiles were proposed as minimalist as possible, in neutral colors, such as: white, beige, gray, nude, black and other similar tones.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 5
            },
            
            {
                index:-1,
                title:"FOREST HOUSE  C",
                fileName:"casa_forest_c",
                subtitle: "Residential",
                location:'Brasov',
                description: "The house is located in Brasov, in the mountain area. The house has a ground floor and an attic. We  integrate it into the mountain landscape by using wood, stone and by proposing the roof  characteristic of the area. The house is open to the outside through numerous wide glazed surfaces, and to the terrace accessible from the living room through sliding doors. The ground floor has the following functions: entrance hall, open-space kitchen with dining room, living room, storage, 3 bedrooms with private bathroom. At the attic level there is the staircase, the hall, 3 bedrooms with their own bathroom and an office.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 5
            },

            {
                index:-1,
                title:"Event hall A",
                fileName:"sala_evenimente_a",
                subtitle: "Horeca/ Public catering",
                location:'Brasov',
                description: "Event hall, located in Ialomita. The maximum capacity of the hall was designed for 200 people. The volume of the hall also includes the related facilities (sanitary groups for customers and staff, kitchen, food storage, changing rooms, etc). The space is glazed, to create a visual connection with the orchard where the event hall is located. Adjacent to the hall is a summer terrace, served by the same kitchen. Access to the event hall is achieved through a pergola on the metal structure that marks the entrance and opens to the water area, with a powerful visual and sensory impact on the exterior design.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 4
            },
            
            {
                index:-1,
                title:"Union square development concept in Rasnov",
                fileName: "concept_amenajare_piata_unirii_din_rasnov",
                subtitle: "Competition",
                location:'Brasov',
                description: "Starting from the idea of the medieval square, we proposed emphasizing it as a wide space in opposition to the narrow streets of the city, which automatically lead all entrance roads to the central square. The project aims to revitalize the Unirii market through the following interventions:\ n - the restoration and  preservation of the buildings that outline the market; \n The presence of the fountain and a church, with the aim of visually reconstructing in the most subtle way, the medieval structure characteristic of medieval squares; \n The demolition of the fence of the Evangelical church that blocks the perspective and its relationship with the market. Also, the old building of the former town hall gained importance in relation to the market; \n We propose the water area with the role of remembering the tradition and ritual of the medieval fountains, which in the past also had a functional role.",
                path:'./assets/',
                pathImages:'./assets/images',
                alt:'',
                className:'thumbImage',
                totalImages: 4
            
            }
        ]
    }
