import { useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import './ProjectPage.desktop.scss'
import ProgressiveImage from '../../../../components/progressiveImage/ProgressiveImage.js'
import ImageWitchAnimationType1 from "../../../../components/selectImageAnimationType1/SelectImageAnimationType1";
import ViewBigImageOnScreen from "../../../../components/viewBigImageOnScreen/viewBigImageOnScreen"
import { Projects } from "../../../../data/ProjectsData";

const ProjectPageDesktop = () => {

     //const language = useSelector((state) => state.language.language);
     let [title, setTitle] = useState('');
     let [description, setDescription] = useState('');
     let [location, setLocation] = useState('');
     let [typeProject, setTypeProject] = useState('');
     let [srcContent, setSrcContent] = useState('');
     let [srcImage, setImportImage] = useState('');
     let [imagesThumbs, setImagesThumbs] = useState([]);
     let [imagesPlaceholderThumbs, setImagesPlaceholderThumbs] = useState([]);
     let [bigImage, setBigImage] = useState(false);
     let [classBigImage, setClassBigImage] = useState('view-big-image-hidden');
     let idsForBigImage = new Map();
     let [statusSetTheProjectIds, setTheProjectIds] = useState(false);
     let [test, setTest] = useState();
     let [dataBigImage, setDataBigImage] = useState();

     const [images, setImage] = useState(null)

     let thisClassName = "images-on-project";
     let indexRoute = null;
     let route = useParams();

     for (const key in route) {

          indexRoute = route[key].split('_')[1];
     }
     const language = useSelector((state) => state.language.language);
     let [projectData, setProjectData] = useState(Projects[language][Number(indexRoute)]);
     function actionOnProject(e, id) {
     }

     //carousel
     const [index, setIndex] = useState(0);
     const handleSelect = (selectedIndex, e) => {
          setIndex(selectedIndex);
     };

   
     const loadImage = index => {
          let id = 'img_' + index;
          let idTiny = 'img_' + index + '_tiny';
          //../../pages/assets/project_1/images/img_1.jpg


          import(`../../../assets/${projectData.fileName}/images/${id}.jpg`).then(module => {
               // imagesThumbs[id] = module.default;
               let updatedValue = [];
               updatedValue.push(module.default);
               setImagesThumbs(imagesThumbs => ([
                    ...imagesThumbs,
                    ...updatedValue
               ]))
          });

          import(`../../../assets/${projectData.fileName}/images/${idTiny}.jpg`).then(module => {
               // imagesThumbs[id] = module.default;
               let updatedValue = [];
               updatedValue.push(module.default);
               setImagesPlaceholderThumbs(imagesPlaceholderThumbs => ([
                    ...imagesPlaceholderThumbs,
                    ...updatedValue
               ]))
          });
     }
     
     function viewImage(dataImage) {
          setDataBigImage(dataImage);
     }
     
     useEffect(() => {
          setProjectData(Projects[language][Number(indexRoute)]);
               if(imagesThumbs.length === 0){
               setTheProjectIds(true);
               for (let index = 0; index < projectData.totalImages; index++) {
                    let path = {};
                    let id = 'img_' + (index+1);
                    let idTiny = 'img_' + (index+1) + '_tiny';
                    path = {
                         pathBigImage: `assets/${projectData.fileName}/images/${id}.jpg`,
                         pathTinyImage: `assets/${projectData.fileName}/images/${idTiny}.jpg`,
                    }
                    idsForBigImage.set(index, { idsForBigImage, path })
                    setTest(idsForBigImage);
               }
               
               
               setImagesThumbs([]);
               
               for (let index = 1; index <= projectData.totalImages; index++) {
               loadImage(index);
          }
          }

          setTitle(projectData.title);
          setDescription(projectData.description);
          setLocation(projectData.location);
          setTypeProject(projectData.subtitle);
          
     
     

     }, [indexRoute, statusSetTheProjectIds, language, projectData])
     return (

          <div>
               <div className="viewBigImage" >
                    {
                         dataBigImage ? <ViewBigImageOnScreen key={Math.random()}  data={dataBigImage}/> : null
                    }
               </div>
               <div className="content-text">

                    <div className="title-project">{title}</div>
                    <div className="type-project">{typeProject}</div>
                    <div className="location-content">{location}</div>
                    <div className="desctiption-project">{description}</div>
                    <div className="content-images-project-page">

                         {
                              imagesThumbs.map((elem, index) => {
                                   let image =


                                        <ProgressiveImage
                                             src={elem}
                                             placeholderSrc={imagesPlaceholderThumbs[index]}
                                             className={'image-project-page'}
                                        />
                                   let imageOriginalSize =


                                        <ProgressiveImage
                                             src={elem}
                                             placeholderSrc={elem}
                                             className={''}
                                        />
                                   let dataImage = {
                                        content: image,
                                   }
                                   let data = {
                                        
                                        path: test.get(index),
                                        classImage: 'class',
                                        indexImage: index,
                                        idsForBigImage: test

                                   }
                                   return (
                                        <div key={Math.random()}className="parentImage" onClick={viewImage.bind(this, data)}>
                                             <ImageWitchAnimationType1 data={dataImage} />

                                        </div>


                                   )
                              })
                         }
                    </div>
               </div>
               {


               }

          </div>
     );
}

export default ProjectPageDesktop;