import './Configurator.scss';
import './stepsCommonStyles.scss';
import { useSelector } from 'react-redux';
import ConfiguratorDesktop from './desktop/Configurator.desktop';
import ConfiguratorTablet from './tablet/Configurator.tablet';
import ConfiguratorMobile from './mobile/Configurator.mobile';
import { useCallback } from 'react';
import emailjs from "emailjs-com";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetItems } from '../../../store/actions/configuratorFormChecksActions';
import ConsoleMessageSentMail from '../../../components/messageSendMail/messageSendMail';
import { useEffect } from 'react';

const Configurator = () => {
  const device = useSelector((state) => state.deviceType.device);
  const [consoleMessageSentMail, setConsoleMessageSentMail] = useState(false);
  let configuratorCheckBox = useSelector((state) => state.configuratorCheckbox.checkboxList);
  const sendEmailCallback = useCallback(sendEmail)
  const dispatch = useDispatch();
  let [countNewOfferRequest, setCountNewOfferRequest] = useState(0);
  const messageSentMail = 'Va multumim! \n Emailul a fost trimis! \n Vom analiza cererea dvs. si va vom contacta in cel mai scurt timp!'
  function sendEmail(e) {
    try {


      console.log('e email:', e)

      e.preventDefault();
      const YOUR_SERVICE_ID = 'service_5ohwuri';
      const YOUR_TEMPLATE_ID = 'template_8zz9ja5';
      const YOUR_USER_ID = 'iTx3A3DbFrPG_ACyJ';

      //if(statusSendMail) return;


      emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, e.target, YOUR_USER_ID)
        .then((result) => {
          console.log('email sent')
          setConsoleMessageSentMail(true);
          //reset configuratorCheckBox 
          dispatch(resetItems());
          //count new offer request
          setCountNewOfferRequest(++countNewOfferRequest);
        }, (error) => {
          console.log('email NOT sent', error.text)
        });
      //setStatusSendMail(true);
    } catch (error) {

    }
  }
  useEffect(() => {
  }, [countNewOfferRequest])
  return (
    <div key = {`newOffer${countNewOfferRequest}`}>
      {
        device === 'mobile' || device === 'mobile_w_max_360' ? <ConfiguratorMobile 
                                                                key = {`newOffer${countNewOfferRequest}`} 
                                                                sendEmailCallback={sendEmailCallback} 
                                                                /> :
        device === 'tablet' ? <ConfiguratorTablet 
                                key = {`newOffer${countNewOfferRequest}`} 
                                sendEmailCallback={sendEmailCallback} 
                                /> :
        device === 'desktop' ? <ConfiguratorDesktop 
                                key = {`newOffer${countNewOfferRequest}`} 
                                sendEmailCallback={sendEmailCallback} 
                                /> : 
        null
      }
      <div>
        {
          consoleMessageSentMail ? <ConsoleMessageSentMail warningText = {messageSentMail}/> : null
        }
      </div>
    </div>
  )
}

export default Configurator;