import { createReducer } from "@reduxjs/toolkit";
import { addCheckboxIntoForm } from "../actions/configuratorFormChecksActions";
import { setItem } from "../actions/configuratorFormChecksActions";
import { resetItems } from "../actions/configuratorFormChecksActions";

const initialState = {
    checkboxList: {
        step1:null,
        step2:null,
        step3:null,
        step4:null,
    },
    selectedItem: -1
}



const configuratorCheckbox = createReducer(initialState, (builder) =>
    builder
        .addCase(addCheckboxIntoForm, (state, action) => {
            let keyItem = action.payload.step;
            
            state.checkboxList[keyItem] = action.payload.data;
        })
        .addCase(setItem, (state, action) => {
            let keyItem = action.payload.step;
            
            state.checkboxList[keyItem] = action.payload.data;
        })
        .addCase(resetItems, (state, action) => {
            for (const key in state.checkboxList) {
                state.checkboxList[key] = null;
            }
        })
        
        
);

export default configuratorCheckbox;