
import './Servicii.scss';
import { useSelector } from 'react-redux';

import ServiciiMobile from './mobile/Servicii.mobile';
import ServiciiTablet from './tablet/Servicii.tablet';
import ServiciiDesktop from './desktop/Servicii.desktop';

function Servicii() {
  const device = useSelector((state) => state.deviceType.device);

  return (
    <div>
      {
      device === 'mobile' || device === 'mobile_w_max_360'? <ServiciiMobile/> : 
      device === 'tablet' ? <ServiciiTablet/> : 
      device === 'desktop' ? <ServiciiDesktop/> : 
      null
    } 
    </div>
  )
}

export default Servicii;
