
import './PagesHieracky.scss';
import { useSelector } from 'react-redux';
import { PagesHierackyDesktop } from './PagesHieracky.desktop';
import { PagesHierackyTablet } from './PagesHieracky.tablet';
import { PagesHierackyMobile } from './PagesHieracky.mobile';


const PagesHieracky = () => {
  const device = useSelector((state) => state.deviceType.device);

  return (
    <div>
      {
      device === 'mobile' ? <PagesHierackyMobile/> : 
      device === 'tablet' ? <PagesHierackyTablet/> : 
      device === 'desktop' ? <PagesHierackyDesktop/> : 
      null
    } 
    </div>
  )
}

export {PagesHieracky};
