export const DataStep3 = {
    RO: {
        constructionsList1 : [
            {
                indexCategory: 1,
                label: 'Casa',
                name: 'Casa'
            },
            {
                indexCategory: 1,
                label: 'Bloc',
                name: 'Bloc'
            }
        ],
        constructionsList2 : [
    
            {
                id: 'Invatamant',
                indexCategory: 2,
                label: 'Scoala',
                name: 'Scoala'
            },
            {
                id: 'Invatamant',
                indexCategory: 2,
                label: 'Universitate',
                name: 'Universitate'
            },
    
    
    
            {
                id: 'Cult si cultura',
                indexCategory: 2,
                label: 'Teatru',
                name: 'Teatru'
            },
            {
                id: 'Cult si cultura',
                indexCategory: 2,
                label: 'Sala de expozitie',
                name: 'Sala de expozitie'
            },
            {
                id: 'Cult si cultura',
                indexCategory: 2,
                label: 'Cinematograf',
                name: 'Cinematograf'
            },
            {
                id: 'Cult si cultura',
                indexCategory: 2,
                label: 'Filarmonica',
                name: 'Filarmonica'
            },
            {
                id: 'Cult si cultura',
                indexCategory: 2,
                label: 'Biserica',
                name: 'Biserica'
            },
    
    
            {
                id: 'Sanatate',
                indexCategory: 2,
                label: 'Spital',
                name: 'Spital'
            }, {
                id: 'Sanatate',
                indexCategory: 2,
                label: 'Sanatoriu',
                name: 'Sanatoriu'
            },
    
            {
    
                id: 'Sport',
                indexCategory: 2,
                label: 'Sala de sport',
                name: 'Sala de sport'
            },
    
    
            {
                id: 'Comert',
                indexCategory: 2,
                label: 'Magazin',
                name: 'Magazin'
            },
            {
                id: 'Comert',
                indexCategory: 2,
                label: 'Supermarket',
                name: 'Supermarket'
            },
            {
                id: 'Comert',
                indexCategory: 2,
                label: 'Depozit',
                name: 'Depozit'
            }
    
        ],
        //cladiri industriale
        constructionsList3 : [
            {
                indexCategory: 3,
                label: 'Fabrica',
                name: 'Fabrica'
            },
            {
                indexCategory: 3,
                label: 'Atelier',
                name: 'Atelier'
            },
            {
                indexCategory: 3,
                label: 'Magazie',
                name: 'Magazie'
            }
        ],
        //cladiri agrozootehnice
        constructionsList4 : [
            {
                indexCategory: 4,
                label: 'Siloz',
                name: 'Siloz'
            },
            {
                indexCategory: 4,
                label: 'Depozit',
                name: 'Depozit'
            },
            {
                indexCategory: 4,
                label: 'Remiza',
                name: 'Remiza'
            }
        ]

    },
    EN: {
        constructionsList1 : [
            {
                indexCategory: 1,
                label: 'House',
                name: 'House'
            },
            {
                indexCategory: 1,
                label: 'Residential building',
                name: 'Residential building'
            }
        ],
        constructionsList2 : [
    
            {
                id: 'Education',
                indexCategory: 2,
                label: 'School',
                name: 'School'
            },
            {
                id: 'Education',
                indexCategory: 2,
                label: 'University',
                name: 'University'
            },
    
    
    
            {
                id: 'Cult and Culture',
                indexCategory: 2,
                label: 'Theater',
                name: 'Theater'
            },
            {
                id: 'Cult and Culture',
                indexCategory: 2,
                label: 'Exhibition space',
                name: 'Exhibition space'
            },
            {
                id: 'Cult and Culture',
                indexCategory: 2,
                label: 'Cinema',
                name: 'Cinema'
            },
            {
                id: 'Cult and Culture',
                indexCategory: 2,
                label: 'Philharmonic',
                name: 'Philharmonic'
            },
            {
                id: 'Cult and Culture',
                indexCategory: 2,
                label: 'Church',
                name: 'Church'
            },
    
    
            {
                id: 'Healthcare',
                indexCategory: 2,
                label: 'Sanatorium',
                name: 'Sanatorium'
            }, {
                id: 'Healthcare',
                indexCategory: 2,
                label: 'Sanatorium',
                name: 'Sanatorium'
            },
    
            {
    
                id: 'Sport',
                indexCategory: 2,
                label: 'Sports hall',
                name: 'Sports hall'
            },
    
    
            {
                id: 'Commerce',
                indexCategory: 2,
                label: 'Shop',
                name: 'Shop'
            },
            {
                id: 'Commerce',
                indexCategory: 2,
                label: 'Supermarket',
                name: 'Supermarket'
            },
            {
                id: 'Commerce',
                indexCategory: 2,
                label: 'Warehouse',
                name: 'Warehouse'
            }
    
        ],
        //cladiri industriale
        constructionsList3 : [
            {
                indexCategory: 3,
                label: 'Factory',
                name: 'Factory'
            },
            {
                indexCategory: 3,
                label: 'Workshop',
                name: 'Workshop'
            },
            {
                indexCategory: 3,
                label: 'Storehouse',
                name: 'Storehouse'
            }
        ],
        //cladiri agrozootehnice
        constructionsList4 : [
            {
                indexCategory: 4,
                label: 'Silo',
                name: 'Silo'
            },
            {
                indexCategory: 4,
                label: 'Farm storage',
                name: 'Farm storage'
            },
            {
                indexCategory: 4,
                label: 'Shed',
                name: 'Shed'
            }
        ]

    }
}