import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './warningCompleteFieldsToNextStep.scss';
import StatusFieldsForNextStepFct from './statusFieldsForNextStep';

const WarningCompleteFields = ({step}) => {
    let timer = null;
    const [warningText, setWarningText] = useState('');
    const [statusWarningConsoleInUse, setStatusWarningConsoleInUse] = useState('console-warning');
    const dataConfigurator = useSelector(state => state.configuratorCheckbox.checkboxList);
    const setText = () => {
        if (dataConfigurator.step1 && step === 1) {
            if (dataConfigurator.step1 === 'PF') {

            } else if (dataConfigurator.step1 === 'PJ') {
                if (dataConfigurator.step2) {
                    if (dataConfigurator.step2.cui.split('').length == 0) setWarningText('Please fill the CUI field');
                } else {
                    setWarningText('Please fill the CUI field')
                }
            }
            return;
        }else if (!dataConfigurator.step1 && step === 1) {
            setWarningText('Please select an option!')
            return
        }
        if (dataConfigurator.step2 && step === 2 ) {
            let wrongFillField = StatusFieldsForNextStepFct(dataConfigurator.step2);
            if (!dataConfigurator.step2.name) return setWarningText('Please fill the Name field');
            if (dataConfigurator.step2.name.split('').length == 0) setWarningText('Please fill the Name field');
            if (wrongFillField.fieldIssue == 'name') return setWarningText('Please enter a valid Name');
            
            if (!dataConfigurator.step2.surname) return setWarningText('Please fill the Surname field');
            if (dataConfigurator.step2.surname.split('').length == 0) setWarningText('Please fill the Surname field');
            if (wrongFillField.fieldIssue === 'surname') return setWarningText('Please enter a valid Surame');
            
            if (!dataConfigurator.step2.phone) return setWarningText('Please fill the Phone field');
            if (dataConfigurator.step2.phone.split('').length == 0) setWarningText('Please fill the Phone field');
            if (isNaN(dataConfigurator.step2.phone)) setWarningText('Please fill the Phone field 2');
            if (wrongFillField.fieldIssue === 'phone') return setWarningText('Please enter a valid Phone');
            
            if (!dataConfigurator.step2.email) return setWarningText('Please fill the Email field');
            if (dataConfigurator.step2.email.split('').length == 0) setWarningText('Please fill the Email field');
                if (wrongFillField.fieldIssue === 'email') return setWarningText('Please enter a valid Email');
                return;
            } 
            if (dataConfigurator.step3 && step === 3 ) {
                
            }else  if (!dataConfigurator.step3 && step === 3 ) {
                setWarningText('Please select an option!')
                return;
            }
            
            setWarningText('Please fill the fields')
            
            
            
            
        }
        useEffect(() => {
            
            if (timer) clearInterval(timer);
            if (!timer) {
                setText();
                if(step === 1 || step === 2){
                setStatusWarningConsoleInUse('console-warning-step-1-2-visible');
            }else if(step === 3 || step === 4){
             
                   setStatusWarningConsoleInUse('console-warning-step-3-4-visible');
               
                
                
            }
            
            timer = setTimeout(function () {
                if(step === 1 || step === 2){
                    setStatusWarningConsoleInUse('console-warning-step-1-2-hide');
                }else if(step === 3 || step === 4){
                       setStatusWarningConsoleInUse('console-warning-step-3-4-hide');
                   
                    
                }
                

                    step = -1;
                

            }, 1400);
        }
    }, []);
    return (
        <div className='container-warning-complete-field'>
            <div className={statusWarningConsoleInUse} >{warningText}</div>
        </div>
    )
}

export default WarningCompleteFields;