
import './Contact.scss';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { Compass } from 'react-bootstrap-icons';
import { Phone } from 'react-bootstrap-icons';
import { Mailbox } from 'react-bootstrap-icons';
import { GlobeAmericas } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ContactData from '../../../data/ContactData';
import { useSelector } from 'react-redux';
import emailjs from "emailjs-com";
import ConsoleMessageSentMail from '../../../components/messageSendMail/messageSendMail';

function Contact() {
	const language = useSelector((state) => state.language.language);
	const [contactData, setContactData] = useState(ContactData.getContact(language));
	const [idSubmit, setIdSubmit] = useState(contactData.btn_name);
	const form = useRef();
	let [statusSendMail, setStatusSendMail] = useState(false);
	let [statusReadyToSendMail, setStatusReadyToSendMail] = useState(null);
	let [countTryToSendMail, setCountTryToSendMail] = useState(0);
	let [warningText, setWarningText] = useState('');
	let [fieldsInput, setFieldsInput] = useState([
		{
			idField: 'name',
			value: ''
		},
		{
			idField: 'phone',
			value: null
		},
		{
			idField: 'email',
			value: null
		},
		{
			idField: 'description',
			value: ''
		},
	]);
	function sendEmail(e) {

		try {
			e.preventDefault();
			const YOUR_SERVICE_ID = 'service_5ohwuri';
			const YOUR_TEMPLATE_ID = 'template_8zz9ja5';
			const YOUR_USER_ID = 'iTx3A3DbFrPG_ACyJ';

			if (statusSendMail) return;


			emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, e.target, YOUR_USER_ID)
				.then((result) => {
					console.log('email sent')
					setStatusSendMail(false);
				}, (error) => {
					console.log('email NOT sent', error.text)
				});
			setStatusSendMail(true);
		} catch (e) {

		}

	}
	//
	function checkFillFileds(idField, value) {
		var data = {
			isReady: false,
			fieldIssue: ''
		};
		if (idField === 'name') {
			if (value.length === 0) {
				data.isReady = false;
				data.fieldIssue = 'Please fill with your name!';
				return data;

			}
			let nameContainsNumbers = false;
			for (const letter of value.split('')) {
				if (!isNaN(letter)) nameContainsNumbers = true;
			}
			if (nameContainsNumbers) {

				data.isReady = false;
				data.fieldIssue = 'Please fill with your name!';
				return data;
			}

		}
		//phone field
		if (idField === 'phone') {
			let phoneNumber = Number(value);

			if (value===0) {

				data.isReady = false;
				data.fieldIssue = 'Please fill with your phone!';
				return data;

			}
			if (isNaN(phoneNumber)) {
				data.isReady = false;
				data.fieldIssue = 'Please fill with your phone!';
				return data;
			}
		}
		//email field
		if (idField === 'email') {
			if (value) {

				if (value.length === 0) {
					data.isReady = false;
					data.fieldIssue = 'Please fill with your email!';
					return data;

				}
			}
			var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			data.isReady = re.test(value);
			if (!data.isReady) {

				data.fieldIssue = 'Please fill with your email!';
				return data;
			}
		}
		if (idField === 'description') {
			if (value) {

				if (value.length < 50) {
					data.isReady = false;
					data.fieldIssue = 'Please fill a description!';
					return data;

				}
			} else {

				data.isReady = false;
				data.fieldIssue = 'Please fill a description!';
				return data;


			}

		}
	}
	//
	function warningCompleteField() {
		setStatusReadyToSendMail(false)
		setCountTryToSendMail(++countTryToSendMail);
		
	}
	//
	function inputFill(index, input) {
		let newFieldsInput = fieldsInput;

		newFieldsInput[index].value = input.target.value;

		setFieldsInput(newFieldsInput);


		//let result = checkFillFileds(idField, input.target.value);
	}
	//
	useEffect(() => {
		//

		let allowSendMail = true;
		for (const elem of fieldsInput) {
			let status = checkFillFileds(elem.idField, elem.value);
			if (status) {
				if (status.isReady === false){ 
				allowSendMail = false; 
				setWarningText(status.fieldIssue);
				break;
				}
			}
		}
		if(allowSendMail) setStatusReadyToSendMail(allowSendMail);
		//
		setContactData(ContactData.getContact(language));
		if (statusSendMail) {
			setIdSubmit('Please wait!');
		} else {

			setIdSubmit(contactData.btn_name);
		}
		document.body.classList.add('home-layout')
		return () => {

			document.body.classList.remove('home-layout')
		}
	}, [language, statusSendMail, countTryToSendMail])

	return (
		<div className='main-contact'>
			<div className='contact-form'>
				<div className='title-contact-section'>{contactData.title_left}</div>

				<form ref={form} className='form-container-contact' onSubmit={sendEmail}>
					<label key={contactData.name_left}>
						<input type="text" placeholder={contactData.name_left} className='element-input-contact' onChange={inputFill.bind(this, 0)} />
					</label>
					<div className='line-under-form'></div>
					<label key={contactData.phone_title}>
						<input type="text" placeholder={contactData.phone_title} className='element-input-contact' onChange={inputFill.bind(this, 1)} />
					</label>
					<div className='line-under-form'></div>
					<label key={contactData.email}>
						<input type="text" placeholder={contactData.email} className='element-input-contact' onChange={inputFill.bind(this, 2)} />
					</label>
					<div className='line-under-form'></div>
					<label key={contactData.message_left}>
						<textarea type="text" placeholder={contactData.message_left} rows="4" cols="30" className='element-input-contact' onChange={inputFill.bind(this, 3)} />
					</label>
					<div className='line-under-form'></div>

					{statusReadyToSendMail ?
						<button className="button-submit-contact" type="submit">{idSubmit}</button>
						:
						<div className="button-submit-contact" onClick={warningCompleteField}>{idSubmit}</div>}
				</form>


			</div>
			<div className='contact-info'>
				<div className='title-contact-section'>{contactData.title_right}</div>
				<div className='content-contact-section'>
					<span className='icon-contact-inside-section'><Compass /></span>
					<span className='title-contact-inside-section'>{contactData.adress_title}</span>
					<span className='content-contact-inside-section'>{contactData.adress_content}</span>
				</div>
				<div className='content-contact-section'>
					<span className='icon-contact-inside-section'><Phone /></span>
					<span className='title-contact-inside-section'>{contactData.phone_title}</span>
					<span className='content-contact-inside-section'>{contactData.phone_content}</span>
				</div>
				<div className='content-contact-section'>
					<span className='icon-contact-inside-section'><Mailbox /></span>
					<span className='title-contact-inside-section'>{contactData.email_name}</span>
					<span className='content-contact-inside-section'>{contactData.email_content}</span>
				</div>
				<div className='content-contact-section'>
					<span className='icon-contact-inside-section'><GlobeAmericas /></span>
					<span className='title-contact-inside-section'>{contactData.website_name}</span>
					<span className='content-contact-inside-section'>{contactData.website_content}</span>
				</div>
			</div>
			<div >{statusReadyToSendMail === false? <ConsoleMessageSentMail key = {Math.random()} warningText = {warningText}/> : null}</div>
		</div>

	)
}

export default Contact;
