const Icon6 = ({_width, _height, _color}) => {
    
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width={_width} height={_height} viewBox="0 0 236.000000 237.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,237.000000) scale(0.100000,-0.100000)"
       fill={_color} stroke="none">
       <path d="M1200 1781 c-5 -11 -10 -49 -10 -85 l0 -66 -34 0 c-66 0 -66 1 -66
       -140 l0 -128 -105 -297 c-57 -164 -106 -299 -107 -301 -2 -1 -32 -10 -68 -19
       -97 -25 -202 -77 -248 -123 -51 -51 -61 -95 -35 -145 18 -36 86 -97 108 -97
       29 0 22 16 -25 59 -67 61 -74 95 -29 145 18 21 55 49 83 63 56 28 187 75 193
       69 2 -2 -12 -50 -31 -106 -41 -115 -43 -132 -20 -155 15 -14 14 -21 -6 -75
       -21 -56 -20 -85 2 -78 6 2 21 31 33 63 19 51 25 60 44 57 38 -6 57 24 111 177
       l51 148 76 7 c96 8 180 8 274 0 l75 -7 49 -146 c52 -151 69 -181 108 -181 18
       0 26 -11 43 -53 18 -45 24 -52 46 -50 54 3 181 66 230 113 44 43 48 50 48 95
       0 45 -4 52 -47 93 -52 51 -157 103 -255 127 -52 13 -67 21 -71 38 -3 12 -49
       146 -102 297 l-98 275 2 110 c1 61 -2 122 -8 138 -9 24 -16 27 -56 27 l-45 0
       0 73 c0 43 -5 78 -12 85 -20 20 -86 15 -98 -7z m70 -86 c0 -58 -2 -65 -20 -65
       -18 0 -20 7 -20 65 0 58 2 65 20 65 18 0 20 -7 20 -65z m110 -205 l0 -100
       -125 0 -125 0 0 100 0 100 125 0 125 0 0 -100z m-234 -331 c-64 -180 -158
       -449 -211 -599 -36 -103 -37 -104 -71 -93 -40 14 -37 39 25 213 77 212 168
       472 204 577 31 91 31 92 67 95 19 2 39 0 43 -3 5 -4 -21 -89 -57 -190z m186
       -29 c37 -107 68 -195 68 -197 0 -2 -29 -3 -65 -3 -58 0 -65 2 -65 20 0 12 -7
       20 -16 20 -9 0 -18 -9 -21 -20 -5 -17 -14 -20 -64 -20 -33 0 -59 2 -59 5 0 21
       145 415 148 404 3 -8 36 -102 74 -209z m53 203 c11 -30 126 -354 146 -413 10
       -30 47 -138 83 -238 36 -101 66 -188 66 -192 0 -12 -63 -31 -72 -22 -8 8 -56
       140 -156 432 -28 80 -73 211 -101 291 -28 80 -51 148 -51 152 0 4 18 7 39 7
       28 0 41 -5 46 -17z m-155 -467 c0 -23 22 -41 34 -29 3 4 6 17 6 30 0 22 3 23
       73 23 l74 0 12 -37 c7 -21 14 -43 17 -48 3 -7 -62 -10 -191 -10 -107 0 -195 0
       -195 1 0 0 7 21 16 47 l16 47 69 0 c66 0 69 -1 69 -24z m530 -184 c126 -49
       193 -104 193 -157 0 -52 -71 -110 -185 -153 l-56 -21 -16 40 c-15 38 -15 41 5
       69 l22 28 -42 116 -41 116 28 -6 c15 -4 56 -18 92 -32z"/>
       </g>
       </svg>
      

      
    )
}

export default Icon6;